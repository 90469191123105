import React  from 'react';  
import Layout from '../components/Layout/Layout';
import ErrorBoundary from '../components/ErrorBoundary';  
const title = 'Home page'; 
class Home extends React.Component {
  constructor(props){    
    super(props);
    this.state = { 
    };    
  }
  componentDidMount() {
    if (document.title) {
      document.title = title;
    }
  }

  render() { 
    return (
      <ErrorBoundary>
      <Layout>
        <h1 className="mdl-typography--title">Welcome to HushVideo!</h1>    
        <div className="section">
          
        </div>
      </Layout>
      </ErrorBoundary>
    );
  }
}

export default Home;
