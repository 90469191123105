import React from 'react';
import PropTypes from 'prop-types';
import { InputSwitch} from 'primereact/inputswitch';
import { DataView } from 'primereact/components/dataview/DataView';
import {Button} from 'primereact/button'; 
import 'primeflex/primeflex.css';
import './plans.css';

class CardListInline extends React.Component {
    
    static propTypes = {
        cardlist: PropTypes.array,
        handleFieldChange: PropTypes.func,
        handlePrimaryChange: PropTypes.func,
        goToPrev: PropTypes.func,
        goToNext: PropTypes.func,
        nextButtonLabel: PropTypes.string
    }
    constructor (props) {
        super(props);  

        this.state = { 
            viewMode: 0, 
            cardlist: props.cardList,
            nextButtonLabel: props.nextButtonLabel || 'Next'
        }; 
        const location= props.location;
        if (location && location.pathname) { 
            var idPosition = location.pathname.indexOf('/cards/');
            if (idPosition > -1) {
                idPosition += '/cards/'.length; 
                const mode=location.pathname.substring(idPosition); 
                if (mode==='new') this.state.viewMode=1; 
            }
        } 
        this.gotoLink = this.gotoLink.bind(this);
        this.rowTemplate = this.rowTemplate.bind(this); 
        this.handleDelete = this.handleDelete.bind(this);
        this.handleNewCard = this.handleNewCard.bind(this);  
        this.handleFieldChange = this.handleFieldChange.bind(this); 
        this.handlePrimaryChange = this.handlePrimaryChange.bind(this);
	}

    gotoLink(target){
        this.props.history.push(target);
    } 
    handleDelete(id) {
        if (this.props.handleDelete) { 
            this.props.handleDelete(id);
        }
    }
    handleFieldChange(e) {
        if (this.props.handleFieldChange) {
            this.props.handleFieldChange(e);
        }
        else {
            let field_id=e.target.id;
            let newSate={
            ...this.state, [field_id]: e.target.value }; 
            this.setState(newSate);
        }
    }
    handleNewCard(e){
        if (this.props.handleNewCard) {
            this.props.handleNewCard(e);
        }
        else {
            this.gotoLink('/profile/cards/new'); 
        }
    }
    handlePrimaryChange(e) {
        if (this.props.handlePrimaryChange) {
            this.props.handlePrimaryChange(e);
        }
        else {
            let field_id=e.target.id;
            var cardlist = Object.assign([], this.state.cardlist);
            let card = cardlist.find(x => x.RowGuid === field_id);
            let selected = null;
            card.IsPrimary = e.target.value ? 1 : 0;
            if (e.target.value) {
                selected = field_id;
                cardlist.forEach(x=>{
                    if ( x.RowGuid !== field_id) x.IsPrimary = 0;
                });
            }
            let newSate={
                ...this.state, cardlist, selectedCard: selected}; 
            this.setState(newSate);
        }
    }    
    rowTemplate(savedCC) {
        const imageRoot = process.env.PUBLIC_URL + '/assets/images/cards/';
        if(!savedCC) {
            console.log('no savedCC');
            return;
        } 

        return (
                <div className="row-details p-grid"> 
                    <div className="p-col-1 hidden-xs-down">
                    <img  style={{marginTop:'0.5em'}} 
                        src={`${imageRoot}${savedCC.CardType}-straight-32px.png`} alt={`${savedCC.CardType}`} />
                    </div>
                    <div className="p-col-3 row-cell">{savedCC.CardNumber}</div>
                    <div className="p-col-2 row-cell">{savedCC.ExpiryDate}</div>
                    <div className="p-col-3 row-cell">{savedCC.CardholderName}</div>
                        
                    <div  className="p-col-1 row-cell"> 
                        <InputSwitch  key={`cp_${savedCC.RowGuid}`} id={savedCC.RowGuid}
                            checked={savedCC.IsPrimary === 1} tooltip="Set Primary Card"
                            onChange={(e) => this.handlePrimaryChange(e)} 
                        /><br/> 
                        {/* {savedCC.IsPrimary === 1 && <span>Primary</span>} */}
                    </div>
                     
                    <div  className="p-col-2 row-cell"> 
                        <Button id={`del_${savedCC.RowGuid}`} icon="pi pi-trash" label="Archive" 
                        onClick={(e) => this.handleDelete(savedCC.RowGuid)}
                        disabled={savedCC.IsPrimary === 1}
                        className="p-button-secondary" style={{top: '-5px', width:'auto'}}/>
                    </div>
                    
                </div>
        );
    }
    
    render () { 
        return (
        <div className="">   
            <div className="p-grid p-fluid">    
                <div className="p-col-12 p-md-12 p-xl-12"> 
                <Button icon="pi pi-plus" label="Add New Card" onClick={(e) => this.handleNewCard(e)}
                className="p-button-secondary" style={{marginRight: '.25em', width:'auto'}}/>
                </div>
                
                <div className="p-col-12 p-md-12 p-xl-12"> 
                {this.state.cardlist && 
                <div> 
                    <DataView className="hv-table-noborder" value={this.state.cardlist} layout="list" itemTemplate={this.rowTemplate}></DataView>
                </div>}
                </div>    
            </div>
            {this.props.goToPrev && 
                <Button onClick={() => this.props.goToPrev()} className="p-button-raised p-button-secondary" label="Back"/> 
            }
            {this.props.goToNext && 
                <Button onClick={() => this.props.goToNext()} className="p-button-raised p-button-primary mr1px" label={this.state.nextButtonLabel}/>   
            }
        </div>
        )
    }
}
 
export default CardListInline;