import React from 'react'; 
import { 
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import About from '../pages/about';
import Home from '../pages/home';  
import ListContactsCTX from '../containers/Contacts/ListContactsCTX';
import ChatCTX from '../containers/Chat/ChatCTX';
import Settings from '../containers/Settings/SettingsList';
import UserView from '../containers/Users/UserView';


class Nav extends  React.Component {
    constructor(props) {
      super(props);
      const reduxState = window.store.getState();
      this.state = { 
        user: reduxState.user,
        history: reduxState.history,
        billing: reduxState.billing
      };
    }      
    render(){
      console.log('using Nav');
      const state4subscription = Object.assign({}, this.state, {activeIndex: 1});
      const state4plans = Object.assign({}, this.state, {activeIndex: 2});
      const state4cards = Object.assign({}, this.state, {activeIndex: 3});
      const state4logs = Object.assign({}, this.state, {activeIndex: 4});
      const state4widget = Object.assign({}, this.state, {activeIndex: 5});
      return ( 
      <Switch>
          {/* <Route exact path='/' component={Home}/>  */}
          <Redirect exact from="/" to="/contacts" />
          <Route path='/about' component={About}/> 
          <Route path='/contacts' component={ListContactsCTX}/>  
          {/* <Route path='/contacts/:id' component={() => <ContactDetailsWithId {...this.props}/>}/>
          <Route path='/contacts/:id/edit' component={() => <ContactDetailsWithId {...this.props}/>}/>
          <Route path='/contacts/new' component={() => <ContactDetailsWithId {...this.props}/>}/> */}
          <Route path='/call' component={() =>ChatCTX}/>
          <Route path='/settings' component={() =>Settings}/>
          <Route exact path='/profile' component={() => <UserView {...this.state}/>}/>
          {/* <Route path='/profile/plans/:id' component={() => <PlanDetails {...this.state}/>}/> */}
          <Route exact path='/profile/subscription' component={() => <UserView {...state4subscription}/>}/>
          <Route path='/profile/plans' component={() => <UserView {...state4plans}/>}/>
          <Route exact path='/profile/history' component={() => <UserView {...state4logs}/>}/>
          <Route exact path='/profile/widget' component={() => <UserView {...state4widget}/>}/>
          {/* <Route exact path='/profile/cards' component={() => <CardList {...this.props}/>}/> 
          <Route exact path='/profile/cards/new' component={() => <CardNew {...this.props}/>}/>  */}
          <Route exact path='/profile/cards' component={() => <UserView {...state4cards}/>}/> 
          <Route exact path='/profile/cards/new' component={() => <UserView {...state4cards}/>}/> 
      </Switch> 
      )
    }
}
export default Nav;