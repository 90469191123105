
import React from 'react'; 
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { DataView } from 'primereact/dataview';
import history from '../../../store/history'; 

import { fetchUserHistory } from '../../../actions/users'; 
import './user.css';

class UserLogs extends React.Component {
    constructor(props) {
        super(props);  
        console.log('ContactView');
        this.defaultPageSize = 10; 
        this.state = { 
            user: props.user.details,            
            info: props.user.info || {isMobile: false},            
            logs: {},
            history: history.hashHistory
        }; 
         
        if (props.user && props.user.logs) { 
            this.state.logs.records = props.user.logs.records || [];
            this.state.logs.totalRecords = props.user.logs.totalRecords || 0; 
            this.state.logs.page = props.user.logs.pageNumber -1 || 0;
            this.state.logs.rows = props.user.logs.pageSize || this.defaultPageSize;
            this.state.logs.first =(this.state.logs.page ) * this.defaultPageSize;
             
          } 
        this.onPage = this.onPage.bind(this); 
        this.rowTemplate = this.rowTemplate.bind(this); 
    }
    
    componentDidMount() {
        if (this.state.user && !this.state.logs.records ) { 
            this.props.fetchUserHistory(this.state.user.AccountId, 1, 10);
        }
    }
 
    rowTemplate(row) {
        if(!row) { 
            return null;
        } 
        return (
              <div className="row-details p-grid"> 
                {row.Connected && 
                <div className="p-col-1 min3em row-cell">
                    <i className="pi pi-thumbs-up" style={{'fontSize': '1.2em', color:'limegreen'}}></i> 
                </div>
                }
                {row.Connected === false && 
                <div className="p-col-1 min3em row-cell">
                    <i className="pi pi-thumbs-down" style={{'fontSize': '1.2em', color:'orangered'}}></i> 
                </div>
                }

                {row.Email && row.Email.length > 0 && 
                <div className="p-col-1 row-cell">
                    <i title={row.Email} className="pi pi-envelope" style={{'fontSize': '1em'}}></i> 
                </div>
                }
                {row.Email && row.Phone.length > 0 && 
                <div className="p-col-1 row-cell">
                    <i title={row.Phone} className="pi pi-mobile" style={{'fontSize': '1em'}}></i> 
                </div>
                }


                {row.Connected && 
                <div className="p-col-2 hidden-xs-down row-cell"> 
                    Connected
                </div>
                }
                {row.Connected === false && 
                <div className="p-col-2 hidden-xs-down row-cell"> 
                    No answer
                </div>
                }
                <div className="p-col-3 row-cell">{row.FirstName} {row.LastName}</div>
                <div className="p-col-2 row-cell">{row.EntryDate}</div>
                <div className="p-col-2 row-cell">{row.StartedAt}</div> 
                <div className="p-col-1 row-cell">{row.Duration} min</div>
              </div>
        );
    }
    onPage(event) {  
      const startIndex = 1 + event.first/this.defaultPageSize; 
      this.props.fetchUserHistory(this.state.user.AccountId, startIndex, this.defaultPageSize);
      this.setState({
          loading: true,
          startIndex
      });
    }
    render() { 

        return ( 
        <div> 
            {this.state.user && this.state.logs && this.state.logs.records && 
            <DataView value={this.state.logs.records} layout="list" itemTemplate={this.rowTemplate}
            paginator={true} rows={this.state.logs.rows} 
            first={this.state.logs.first}   
            lazy  totalRecords={this.state.logs.totalRecords} paginatorPosition={'top'}
            onPage={this.onPage} > 
            </DataView>
            }
            {this.state.user && (!this.state.logs || !this.state.logs.records) &&
            <div>You did not make any calls yet.</div>
            }
        
        </div>  
    );
}
}
const mapStateToProps = (state) => {
    console.log('UserLogView mapState'); 
    console.log(state);
    let newState={
      error: state.error && state.error.message, 
      user: state.user
    }; 
    console.log(newState);
    return newState;
  }
const mapDispatchToProps = (dispatch) => { 
  return bindActionCreators({ fetchUserHistory }, dispatch);
}

const UserLogsView = connect(mapStateToProps, mapDispatchToProps)(UserLogs)
export default UserLogsView;
