import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { isPlainObject } from 'lodash';
import {Growl} from 'primereact/growl'; 
import Initial from './view/components/Elements/Initial';
import Config from './config';
//import { history } from './store';
export class AppProfile extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            user: props.user.details,
            expanded: false
        };
        console.log('APPROFILE');
        console.log(props);
        this.onClick = this.onClick.bind(this);
        this.gotoLink = this.gotoLink.bind(this);
    }
    componentDidMount(){
        const user = this.state.user;
        if (user) {
            const status = user.Status; 
            if (status === "-1" && this.growl){
                this.growl.show({severity: 'warn', summary: 'Registration is not complete', detail: <div>Please activate your account</div>});
            }
        }
    }
    onClick(event) {
        this.setState({expanded: !this.state.expanded});
        event.preventDefault();
    }
    gotoLink(target){
        this.props.history.push(target);
    }    
    render() {
        var initial=this.state.user.FirstName ? this.state.user.FirstName.substring(0,1) : '?';
        var avatar = null;
        if (this.state.user && this.state.user.Avatar) {
            const ts = new Date().getTime();
            const avatarUrl = `${Config.baseUrl}/upload/userpic/${this.state.user.AccountId}?_ts=${ts}`;
            avatar = { backgroundImage: `url(${avatarUrl})`}; 
        } 
        return  (
            <div className="layout-profile">
                {avatar && 
                <div className="hv-sidebar-avatar">
                    <div className="circle-avatar" style={avatar}></div>
                </div>}
                {avatar === null && 
                <div className="hv-sidebar-avatar">
                    <Initial small={true} letter={initial} id={this.state.contact_id} style={{border:'1px solid #ffffff'}}></Initial>
                </div>
                }
                
                <button className="p-link layout-profile-link" onClick={this.onClick}>
                    <span className="username">{this.state.user.FirstName} {this.state.user.LastName}</span>
                    {/* <i className="pi pi-fw pi-cog"/> */}
                </button>
                {/* <ul className={classNames({'layout-profile-expanded': this.state.expanded})}>
                    <li><button className="p-link" onClick={()=>this.gotoLink('/profile')}>
                        <i className="pi pi-fw pi-user"/><span>
                        Account
                    </span></button></li>
                    <li><button className="p-link"><i className="pi pi-fw pi-inbox"/>
                        <span>Notifications</span><span className="menuitem-badge">2</span></button></li>
                    <li><button className="p-link" onClick={()=>{window.location = '/'}}>
                        <i className="pi pi-fw pi-power-off"/><span>Logout</span></button></li>
                </ul> */}
                <Growl ref={(el) => this.growl = el} />
            </div>
        );
    }
}