import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Chat from './Chat'
import { startChat, endChat } from '../../../actions/chat'
const mapStateToProps = (state, props) => { 
	 return {...state }
}
const mapDispatchToProps = (dispatch, props, state) => {
	 console.log('bindActionCreators chat')
	 return bindActionCreators({ startChat, endChat }, dispatch);
}
const ChatCTX = connect(mapStateToProps, mapDispatchToProps)(Chat);
export default ChatCTX;

