import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Login from './Login.jsx';
import { login, getDeviceInfo, sendCode, verifyCode, register } from '../../../actions';  

const mapStateToProps = (state, props) => {
  console.log('mapState');
  console.log(state);
  return { ...state } // error: state.error && state.error.message
}

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators({ login, getDeviceInfo, sendCode, verifyCode, register }, dispatch)

const LoginCTX = connect(mapStateToProps, mapDispatchToProps)(Login)
const LoginWithRoute = withRouter(LoginCTX);
export default LoginWithRoute
