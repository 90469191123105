import React from 'react'
import { InputText } from 'primereact/components/inputtext/InputText';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/components/button/Button';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
class FieldWithType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        value: props.value,
        label: props.label,
        placeholder: props.placeholder,
        typeValue: props.typeValue,
        typeList: props.typeList
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.hanldeBlur = this.hanldeBlur.bind(this);
  }      

  componentDidMount(){
    if (this.nameInput && this.state.value === '') { 
      if (this.nameInput.element) {
        this.nameInput.element.focus(); 
      }
      if (this.nameInput.input) {
        this.nameInput.input.focus(); 
      }
    }
  } 
  hanldeBlur(){
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }
  handleFieldChange(type, e) { 
    let newSate={ value: e.target.value};
    if (type === 'input') {
      this.setState(newSate);
      if (this.props.onFieldChange) {
        this.props.onFieldChange(e);
      }
    }
    else { 
      newSate={ typeValue: e.target.value};
      this.setState(newSate);
      if (this.props.onTypeChange) {
        this.props.onTypeChange(e);
      }
    }
}
  render() { 
    return (   
       <>
       <div className="p-col-12 p-md-6 p-xl-6">
        <div className="p-inputgroup"> 
          {this.props.dataType === 'phone' &&
            <>
              <span className="p-inputgroup-addon">
                  <i className="pi pi-sign-in"></i> 
              </span>
              <InputMask mask="(999) 999-9999? x99999" value={this.state.value} 
                placeholder="(999) 999-9999" onBlur={this.hanldeBlur} 
                ref={(input) => { this.nameInput = input; }}
                onChange={(e) => this.handleFieldChange('input',e)}></InputMask>
            </>
          } 
          {this.props.dataType === 'email2' &&
            <InputMask mask="*@*" value={this.state.value} 
              placeholder="__@__.__"  onBlur={this.hanldeBlur}
              onChange={(e) => this.handleFieldChange('input',e)}></InputMask>
          } 
          {this.props.dataType === 'email' &&
          <>
            <span className="p-inputgroup-addon">
                <i className="pi pi-envelope"></i> 
            </span>
            <InputText placeholder={this.state.placeholder} 
              value={this.state.value}  onBlur={this.hanldeBlur}
              ref={(input) => { this.nameInput = input; }}
              onChange={(e) => this.handleFieldChange('input',e)}/> 
          </>
          } 
          </div>
       </div>
       <div className="p-col-12 p-md-6 p-xl-6"> 
        <div className="p-inputgroup"> 
        <Dropdown value={this.state.typeValue} options={this.state.typeList} 
            onChange={(e) => this.handleFieldChange('dropdown',e)}  onBlur={this.hanldeBlur} 
            editable={true} placeholder={this.props.label + ' type'} style={{minWidth: 'unset'}} />
            <Button icon="pi pi-minus" className="p-button mr1px" onClick={this.props.onRemove}/>
            <Button icon="pi pi-plus" className="p-button" onClick={this.props.onAdd} />
        </div>
        </div>
       </>
    )
  }
}
export default FieldWithType;
