
import React from 'react'; 
import { Link } from 'react-router-dom';
import {produce, setAutoFreeze} from "immer"
import qs from 'qs'; 
import cloneDeep from 'clone-deep';
 
import {TieredMenu} from 'primereact/tieredmenu';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Button } from 'primereact/components/button/Button';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {InputSwitch} from 'primereact/inputswitch';
import {InputTextarea} from 'primereact/inputtextarea';
import {FileUpload} from 'primereact/fileupload';
import {Growl} from 'primereact/growl';
import * as Helper from '../../components/Helper';
import Validator from '../../components/SmartForm';
import Config from '../../../config';
import LinkButton  from '../../components/Elements/LinkedButton';
import Initial  from '../../components/Elements/Initial';
import FieldWithType from '../Common/FieldWithType';
import './contacts.css';
import { extendWith } from 'lodash';

class contactDetails extends React.Component {
    constructor(props) {
        super(props); 
        this.props = props;
        this.contact_id = null;
        this.contact_list = [];
        this.newContact = {
            Id: "ADD", AccountId: "00000000-0000-0000-0000-000000000000", 
            FirstName:'',MidName:'',LastName:'', Comment:'',
            EmailList: '[]', PhoneList:'[]', Address: '',
            Emails: [],Phones: [], LastCall:"2000-01-01T00:00:00",         
        }
        this.contact= {}; 

        this.state = {
            avatar:null,
            editable:false,
            user: props.user.details,
            info: props.user.info || {isMobile: false},
            contact: (props.contact && props.contact.current) 
            ? cloneDeep(props.contact.current) 
            : cloneDeep(this.newContact)
        };
        this.newContact.AccountId = this.state.user.AccountId;
 
        if (this.state.contact && this.state.contact.EmailList) {
            this.contact = cloneDeep (this.state.contact);
            this.contact.Emails = JSON.parse(this.state.contact.EmailList);  
            this.contact.Phones = JSON.parse(this.state.contact.PhoneList);  
            this.state.contact.Emails=cloneDeep(this.contact.Emails);
            this.state.contact.Phones=cloneDeep(this.contact.Phones);
        }

        console.log('this.state and props in contactDetails');
        console.log(this.state);

        console.log(props);
        if (props && props.location && props.location.pathname) {
            const mode = (props.location.pathname.indexOf('add') >-1 ? 'edit' : 'display');
            this.state = {...this.state,
                contact: this.newContact,
                editable: (mode === 'edit'),
                mode };
            this.contact = cloneDeep (this.state.contact);
        }
        this.handleSave = this.handleSave.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleFieldWithTypeChange = this.handleFieldWithTypeChange.bind(this);
        this.handleEditableStateChange = this.handleEditableStateChange.bind(this);
        this.onAddMethod = this.onAddMethod.bind(this); 
        this.onRemoveMethod = this.onRemoveMethod.bind(this);
        this.handleInvite = this.handleInvite.bind(this);
        this.handleMenu = this.handleMenu.bind(this);
        this.hanldeBlur = this.hanldeBlur.bind(this);
        this.checkUpload = this.checkUpload.bind(this);
        this.gotoLink = this.gotoLink.bind(this);
        this.refreshContact = this.refreshContact.bind(this);
      }
componentDidMount() { 
    console.log('calling getContact in componentDidMount'); 
    if (this.state.contact && this.props.contact.list ) { 
        if (this.props.match.params.id) {           
            this.contact_id = this.props.match.params.id.toUpperCase();
            if (this.contact_id === 'ADD') {
                this.setState({contact_id:'ADD'});
                return;
            }
            this.contact = cloneDeep(Helper.getItemFromArray(this.props.contact.list,this.contact_id,'Id'));
            if (!this.contact) {this.contact = cloneDeep(this.props.contact.current);}
            if (this.contact_id !== 'ADD' && !this.contact)  {
                //this.props.history.push('/contacts');
                this.props.getContact(this.contact_id);
            }  
            else { 
                if (this.contact){
                    if (this.contact.EmailList) { this.contact.Emails = JSON.parse(this.contact.EmailList); }
                    if (this.contact.PhoneList) { this.contact.Phones = JSON.parse(this.contact.PhoneList); }
                    this.setState({
                        contact_id: this.contact_id, 
                        contact: cloneDeep(this.contact)
                    });
                }
            }
        }
    }
}

gotoLink(target){
    this.props.history.push(target);
} 
onAddMethod(type, details){
    setAutoFreeze(false);
    if (type ==='phone') {
        const idx = this.state.contact.Phones.length;
        const newItem = {
            Index: idx,
            Id: Helper.generateUUID(),
            PhoneNumber: "",
            PhoneType: ""
            };
        const nextState = produce(this.state, draftState => {
            draftState.contact.Phones.push(newItem);
            draftState.contact.PhoneList = JSON.stringify([newItem]);
            });
        this.setState(nextState);
        this.contact.Phones.push(newItem);
    }
    if (type ==='email') {  
        const idx = this.state.contact.Emails.length;
        const newItem = {
            Index: idx,
            Id: Helper.generateUUID(),
            EmailAddress: "",
            EmailType: ""
            };
        const nextState = produce(this.state, draftState => {
            draftState.contact.Emails.push(newItem);
            draftState.contact.EmailList = JSON.stringify([newItem]);
        });
        this.setState(nextState);
        this.contact.Emails.push(newItem);
    } 
}
onRemoveMethod(type,  id,  index){ 
    if (type ==='phone') {
        var Phones = this.state.contact.Phones.filter((item) => {
            if (item.Id !== id) return item; 
        }); 
        const nextState = produce(this.state, draftState => {
            draftState.contact.Phones = Phones;
            draftState.contact.PhoneList = JSON.stringify(Phones);
        });
        this.contact.Phones.splice(index);
        this.setState(nextState); 
    } 
    if (type ==='email') {
        var Emails = this.state.contact.Emails.filter((item) => {
            if (item.Id !== id) return item; 
        }); 
        const nextState = produce(this.state, draftState => {
            draftState.contact.Emails = Emails;
            draftState.contact.EmailList = JSON.stringify(Emails);
        });
        this.contact.Emails.splice(index);
        this.setState(nextState);
    } 
}
handleSave(){
    const contact_info = this.state.contact;
    
    let errors = [];
    this.growl.clear(); 
    this.state.contact.Emails.forEach((x)=>{
        if (x.length>0 && !Validator.emailIsValid(x)) {
            const key=`ee${x}`;
            errors.push(<div id={key}>Email Address {x} is invalid.</div>); 
        }
    });
    this.state.contact.Phones.forEach((x)=>{
        if (x.length>0 && !Validator.phoneIsValid(x)) {
            const key=`ee${x}`;
            errors.push(<div id={key}>Phone Number {x} is invalid.</div>); 
        }
    });
    if (!this.state.contact.FirstName) errors.push(<div id='efn'>First Name is missing.</div>);
    if (!this.state.contact.LastName) errors.push(<div id='eln'>Last Name is missing.</div>);
    if (errors.length > 0) {
        this.growl.show({severity: 'warn', summary: 'Invalid Contact Information', detail: <div>{errors}</div>});
        return;
    }
    if (typeof(this.props.editContact)==='function') {
        console.log('calling editcontact');
        if (this.contact_id === 'ADD') {
            this.contact_id = Helper.generateUUID();
            contact_info.Id = this.contact_id;
            if (contact_info.Emails.length>0) {
                contact_info.Emails[0].inUse =  true;
                contact_info.EmailList = JSON.stringify(contact_info.Email);
            }
            if (contact_info.Phones.length>0) {
                contact_info.Phones[0].inUse =  true;
                contact_info.PhoneList = JSON.stringify(contact_info.Phones);
            }
            this.props.editContact( this.contact_id, contact_info);
            this.setState({ message: 'Successfully updated patinet record!', 
            mode:'display', editable:false }); 
            this.gotoLink(`/contacts/${this.contact_id}`);
        }
        else {
            this.props.editContact( this.contact_id, contact_info);
            this.setState({ message: 'Successfully updated patinet record!', mode:'display', editable:false });
        }
        
    }
    else {
        console.log('cannot save :( - no editContact function defined');
    }
    this.setState({ mode:'display', editable:false });
    // this.refreshContact();
}
handleInvite(){ 
    const phone = this.state.contact.Phones.find(x => x.inUse);
    const email = this.state.contact.Emails.find(x => x.inUse);
    if (!phone && !email) {
        this.growl.show({severity: 'warn', summary: 'Please select email or phone', detail: 'You need to select a mean of communication where we will send invite'});
    }
    else {
        const roomName = this.state.contact.Id.substring(0,8).toUpperCase();
        this.props.inviteContact(this.props.user.details.Id,this.state.contact);
        window.open(`${Config.videoUrl}?id=${roomName}`, "_blank");
    }
}
handleEditableStateChange(editable) {
    if (editable=== false && this.contact_id==='ADD') {
        this.props.history.push('/contacts');
    }
    else { 
    // this.setState({...this.state, 
    //     mode: editable ? 'edit' : 'display', 
    //     editable}); 
        if (editable) { 
            this.safeCopy = cloneDeep(this.state);
            this.setState({...this.state, 
                mode: editable ? 'edit' : 'display', 
                editable});
        }
        else {
            this.setState(this.safeCopy);
        } 
    }

}
handleDelete(){
    console.log('Delete');
}
handleFieldChange(e) {
    let field_id=e.target.id;
    let newValue = e.target.value;
    if (field_id === 'Comment') {
        // newValue = encodeURI(newValue);
    }
    let newSate={
    ...this.state, contact:{ ...this.state.contact, [field_id]: newValue}
    };
    this.setState(newSate);
}
handleCheckBox(type, id, e) {
    var item = {};
    var nextState;
    switch(type) {
        case 'phone':
            const phones = cloneDeep(this.state.contact.Phones);
            item = phones.find(x => x.Id === id);
            item.inUse = e.target.value; 
            nextState = produce(this.state, draftState =>
                {draftState.contact.Phones = phones});
            this.setState(nextState);         
            break; 
        case 'email':
            const emails = cloneDeep(this.state.contact.Emails);
            item = emails.find(x => x.Id === id);
            item.inUse = e.target.value;
            nextState = produce(this.state, draftState =>
                {draftState.contact.Emails = cloneDeep(emails)});
            this.setState(nextState);
            break; 
    }
    if (this.props.updateContactCommunications) {
        this.props.updateContactCommunications(this.state.user.AccountId, nextState.contact);
    }
}
handleFieldWithTypeChange(type, id, e) {
    var item; 
    switch(type) {
        case 'phone':
            item = this.contact.Phones.find(x => x.Id === id);
            if (item) item.PhoneNumber = e.target.value;          
            break;
        case 'phoneType':
            item = this.contact.Phones.find(x => x.Id === id);
            if (item) item.PhoneType = e.target.value;
            break;
        case 'email':
            item = this.contact.Emails.find(x => x.Id === id);
            if (item) item.EmailAddress = e.target.value;
            break;
        case 'emailType':
            item = this.contact.Emails.find(x => x.Id === id);
            if (item) item.EmailType = e.target.value;
            break;
    }
    const nextState = produce(this.state, draftState =>
        {
            draftState.contact.Phones = cloneDeep(this.contact.Phones);
            draftState.contact.Emails = cloneDeep(this.contact.Emails);
            draftState.contact.EmailList = JSON.stringify(this.contact.Emails);
            draftState.contact.PhoneList = JSON.stringify(this.contact.Phones);
        }
        );
    this.setState(nextState);
}
hanldeBlur(){
    /*
    const phones = cloneDeep(this.state.contact.Phones);
    const emails = cloneDeep(this.state.contact.Emails);
    const nextState = produce(this.state, draftState =>
        {
            draftState.contact.Phones = phones;
            draftState.contact.Emails = cloneDeep(emails);
        });
    this.setState(nextState);
    */
   let newSate={
    ...this.state, contact:{ ...this.state.contact, 
        EmailList: JSON.stringify(this.state.contact.Emails),
        PhoneList: JSON.stringify(this.state.contact.Phones)
    }
    }; 
    this.setState(newSate);
}
handleMenu(event) {
    event.preventDefault();
    event.stopPropagation();
    this.cm.show(event);
    console.log('handleMenu');
    return false;
}

checkUpload(e){
    console.log(e);
    if (e.size>this.maxFileSize){
        this.growl.show({severity: 'warn', summary: 'Image Upload Failed', detail: 'File size exceed 1Mb'});
    }
}
refreshContact(id){
    // if (id) {
    //     this.props.getContact(id); // new contact
    // }
    // else {
        this.props.getContact(this.state.contact_id);
    //}
}
render() {
    const menu =[ 
        {
            label:'Edit',
            icon:'pi pi-fw pi-user-edit',
            command: () => {
                this.handleEditableStateChange(true);
                this.menu.hide();
            }
        },
        {
            label:'Change Avatar',
            icon:'pi pi-fw pi-camera',
            command: () => {
                this.upload.fileInput.click();
                this.menu.hide();
            }
         },
        {
            label:'Delete',
            icon:'pi pi-fw pi-trash',
            command: () => {
                this.handleDelete();
                this.menu.hide();
            }
         },
        {
           label:'Go Back',
           icon:'pi pi-fw pi-arrow-left',
           command:()=>{ window.location.hash="/contacts"; }
        }
     ];
    let phoneTypes = [
        {label: 'Cell', value: 'Cell'},
        {label: 'Home', value: 'Home'},
        {label: 'Office', value: 'Office'},
        {label: 'Other', value: 'Other'}
    ];
    let emailTypes = [ 
        {label: 'Main', value: 'Main'},
        {label: 'Private', value: 'Private'},
        {label: 'Office', value: 'Office'},
        {label: 'Other', value: 'Other'}
    ];
    const ApiRoot = Config.baseUrl;
    const lp_wide = {minWidth:'8em',padding:'0.5em'}; 
    const noLabel = {height:'1.2rem'};
    const fixedW = {width: '20em', float: 'left'};
    const fixedW22 = {width: '24.2em', float: 'left'};
    const floatLeft = {float: 'left'};
    const floatRight = {float: 'right'};
    var initial = '?';
    if (this.state.contact && this.state.contact.FirstName && this.state.contact.FirstName.length>0){
        initial = this.state.contact.FirstName.substring(0,1).toUpperCase();
    } 
    console.log('>>>>>  Contact Details');
    console.log(this.state);
     
    if (this.state.contact && this.state.contact.Avatar && this.state.avatar === null) {
        const ts = new Date().getTime();
        const avatarUrl = `${Config.baseUrl}/upload/logo/${this.state.contact.Id}?_ts=${ts}`;
        const avatar = { backgroundImage: `url(${avatarUrl})`};
        this.setState({avatar});
    } 
    // if (this.nameInput && this.state.mode === 'edit') { 
    //     if (this.nameInput.element) {
    //       this.nameInput.element.focus(); 
    //     }
    //     if (this.nameInput.input) {
    //       this.nameInput.input.focus(); 
    //     }
    //   }
    return ( 
        <div> 
        <TieredMenu model={menu} popup={true} ref={el => this.menu = el} />

        {this.state.contact_id && this.state.mode === 'edit' &&
        <div>
            <div className="p-grid p-fluid">    
                <div className="p-col-12 p-md-3 p-xl-3">
                    {this.state.avatar && 
                    <div className="ma30px">
                        <div className="circle-avatar" style={this.state.avatar}></div>
                    </div>}
                    {this.state.avatar === null && 
		                <Initial letter={initial} id={this.state.contact_id}></Initial>
                    }
                </div>            
                <div className="p-col-12 p-md-9 p-xl-9">
                    <div className="hv-upload">
                    <FileUpload maxFileSize={1000000} onUpload={this.refreshContact} 
                    onValidationFail = {(e) => this.checkUpload(e)} name="avatar" 
                    url={`${ApiRoot}/upload/?id=${this.state.contact_id}&aid=${this.state.user.AccountId}`} 
                    auto={true} mode="basic" />
                    </div>
                </div>    
                <div className="p-col-12 p-md-6 p-xl-6">
		            <label className="lp-label">First Name</label>
                    <InputText placeholder="FirstName" id="FirstName" 
                    //ref={(input) => { this.nameInput = input; }}
                    autoFocus
                    readOnly={!this.state.editable}
                    onChange={this.handleFieldChange}
                    value={this.state.contact.FirstName} />
                </div>
                <div className="p-col-12 p-md-6 p-xl-6">
		            <label className="lp-label">Last Name</label>
                    <InputText placeholder="LastName" id="LastName" 
                    readOnly={!this.state.editable}
                    onChange={this.handleFieldChange}
                    value={this.state.contact.LastName} />
                </div>
                
                <div className="p-col-12 p-md-12 p-xl-12">
		            <label className="lp-label">Private notes</label>
                    <InputTextarea rows={2} cols={30} value={this.state.contact.Comment} 
                    id="Comment" onChange={this.handleFieldChange} autoResize={true} />

                </div>

                <div className="p-col-12 p-md-6 p-xl-6"> 
		            <label className="lp-label">Contact Info</label>
                </div>

                <div className="p-col-12 p-md-6 p-xl-6"> 
                <div style={floatRight}>
                {this.state.contact && this.state.contact.Emails && this.state.contact.Emails.length === 0 && 
                <span><a onClick={(e) => this.onAddMethod('email',e)}>Add email</a></span>
                }
                <span style={lp_wide}></span>
                {this.state.contact && this.state.contact.Phones && this.state.contact.Phones.length === 0 && 
                <span><a onClick={(e) => this.onAddMethod('phone',e)}>Add phone</a></span>
                }
                </div>
                </div>
                
                {this.state.contact && this.state.contact.Emails && 
                    this.state.contact.Emails.map((item, index) => 
                        <FieldWithType key={`fe_${index}`}
                            typeList={emailTypes} label={"Email"} index={index} id={item.Id}
                            placeholder={"Email"} value={item.EmailAddress} typeValue={item.EmailType} 
                            onAdd = {(e) => this.onAddMethod('email',e)}  dataType = {"email"}
                            onRemove = {(e) => this.onRemoveMethod('email', item.Id, e)} onBlur={this.hanldeBlur}
                            onFieldChange={(e)=> this.handleFieldWithTypeChange('email',item.Id, e)} 
                            onTypeChange = {(e)=> this.handleFieldWithTypeChange('emailType',item.Id, e)}>        
                        </FieldWithType>)
                }
                {this.state.contact && this.state.contact.Phones && 
                    this.state.contact.Phones.map((item, index) => 
                        <FieldWithType  key={`fp_${index}`}
                            typeList={phoneTypes} label={"Phone"} index={index}
                            placeholder={"Phone"} value={item.PhoneNumber} typeValue={item.PhoneType} 
                            onAdd = {(e) => this.onAddMethod('phone',e)} dataType = {"phone"} onBlur={this.hanldeBlur}
                            onRemove = {(e) => this.onRemoveMethod('phone',item.Id, e)}
                            onFieldChange={(e)=> this.handleFieldWithTypeChange('phone',item.Id, e)} 
                            onTypeChange = {(e)=> this.handleFieldWithTypeChange('phoneType',item.Id, e)}>       
                        </FieldWithType>)
                }

                <div className="p-col-12 p-md-12 p-xl-12">
                    <InputText placeholder="Address"  value={this.state.contact.Address}  
                    id="Address" onChange={this.handleFieldChange} />
                </div>

                </div>
                <div className="ui-g">  
                    {this.state.editable &&
                    <Button className="p-button-raised p-button-info mr1px"
                        style={lp_wide} label="Save" onClick={this.handleSave} />
                    }
                    {/* <LinkButton className="p-button-raised p-button-secondary mr1px"
                        to='/contacts' style={lp_wide} label="Cancel" /> */}
                    
                    <Button className="p-button-raised p-button-secondary mr1px"
                        style={lp_wide} label="Cancel" onClick={(e)=>this.handleEditableStateChange(false)} />
                    <Button className="p-button-raised p-button-secondary mr1px"
                        style={lp_wide} label="Delete" onClick={this.handleDelete} />
                </div>
            </div>
         } 
        {this.state.contact_id && this.state.mode === 'display' &&
         <div>
            <div className="hv-noshow">
            <FileUpload maxFileSize="1000000" onUpload={this.refreshContact}  ref={el => this.upload = el}  
            onValidationFail = {(e) => this.checkUpload(e)} name="image" 
            url={`${ApiRoot}/upload/?id=${this.state.contact_id}&aid=${this.state.user.AccountId}`} 
            auto={true} mode="basic" />
            </div>
         <div className="p-grid p-fluid">    
            <div className="p-col-12 p-md-3 p-xl-3">
                {this.state.avatar && 
                <div className="ma30px" >
                    <div className="circle-avatar" style={this.state.avatar}></div>
                </div>}
                {this.state.avatar === null && 
                    <Initial letter={initial} id={this.state.contact_id}></Initial>
                }
                {this.state.info.isMobile && 
                <Button icon="pi pi-ellipsis-v" style={{bottom:'11em'}} 
                    onClick={(event) => this.menu.toggle(event)}
                    className="p-button p-button-rounded p-button-secondary mr1px hv-float-btn"/>
                }
            </div>   
            {!this.state.info.isMobile && 
            <div className="p-col-12 p-md-9 p-xl-9 hidden-xs-down">
            <Button icon="pi pi-ellipsis-v" style={floatLeft} 
                onClick={(event) => this.menu.toggle(event)}                
                style={{margin:'3em'}}
                className="p-button p-button-rounded p-button-secondary mr1px"/>
            </div> 
            }        
            <div className="p-col-12 p-md-12 p-xl-12">
                 <label className="lp-label hv-h2">{this.state.contact.FirstName} {this.state.contact.LastName}</label>   
             </div>  
             {this.state.contact.Comment &&
                <div className="p-col-12 p-md-12 p-xl-12">
                    <label className="lp-label">{this.state.contact.Comment}</label>   
                </div> 
            }
            <div className="p-col-12 p-md-12 p-xl-12" style={{borderBottom:'1px solid #c0c0c0', marginBottom:'1em'}}>        
            </div>
             {this.state.contact && this.state.contact.Emails && this.state.contact.Emails.map((item, index) =>
             { 
                if (item.EmailAddress !== '') return (
                    <div className="p-col-12 p-md-12 p-xl-12" key={`re_${index}`}>
                        <div style={fixedW}>
                        <label  key={`le_${index}`} className="lp-label">{item.EmailAddress} ({item.EmailType})</label>
                        </div>
                        <InputSwitch  key={`ce_${index}`}
                        style={floatLeft} checked={item.inUse}
                        onChange={(e) => this.handleCheckBox('email',item.Id,e)} />
                    </div>);}
             )}

            {this.state.contact && this.state.contact.Phones && this.state.contact.Phones.map((item, index) => 
            { 
                if (item.PhoneNumber !== '') return (
                    <div className="p-col-12 p-md-12 p-xl-12" key={`re_${index}`}>
                        <div style={fixedW}>
                        <label  key={`lp_${index}`} className="lp-label">{item.PhoneNumber} ({item.PhoneType})</label>
                        </div>
                        <InputSwitch  key={`cp_${index}`}
                        style={floatLeft} checked={item.inUse}
                        onChange={(e) => this.handleCheckBox('phone',item.Id,e)} />
                    </div>);}
                )}
            <div className="p-col-12 p-md-12 p-xl-12">
            <label className="lp-label">{this.state.contact.Address}</label>
            </div> 
              
         </div>
        
         <div className="p-col-12 p-md-12 p-xl-12" style={{marginTop:'1em'}}>        
            <Button className="p-button-raised mr1px"
                style={lp_wide} label="Call" onClick={this.handleInvite} />
            <Button className="p-button-raised p-button-secondary hidden-xs-down mr1px"
                style={lp_wide} label="Edit" onClick={(e)=>this.handleEditableStateChange(true)} />
            <LinkButton className="p-button-raised p-button-secondary mr1px" ref={el => this.state.exit = el}
                to='/contacts' style={lp_wide} label="Close" />
        </div>
     </div>  
     
        }
        
        <Growl ref={(el) => this.growl = el} />
        </div>  
    );
    }

}

export default contactDetails;

