const stripAlpha = require('./strip-alpha');
const formatPhone = require('./format-phone');
const formatCC = require('./format-credit-card');
const getCardType = require('./get-card-type');
const emailIsValid = require('./validate-email');
const cardIsValid = require('./validate-card');
const phoneIsValid = require('./validate-phone');
const setCaretPosition = require('./setCaretPosition');
const Validator = {
    stripAlpha,
    formatPhone,
    getCardType,
    formatCC,
    emailIsValid,
    cardIsValid,
    phoneIsValid,
    setCaretPosition
}
export default Validator;