
import React from 'react'; 
import {produce, setAutoFreeze} from "immer"
import cloneDeep from 'clone-deep';
 
import {TieredMenu} from 'primereact/tieredmenu';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Button } from 'primereact/components/button/Button';
import {InputTextarea} from 'primereact/inputtextarea';
import {FileUpload} from 'primereact/fileupload';
import {Growl} from 'primereact/growl';
import * as Helper from '../../components/Helper';
import Config from '../../../config';
import LinkButton  from '../../components/Elements/LinkedButton';
import Initial  from '../../components/Elements/Initial';
import FieldWithType from '../Common/FieldWithType';
import './user.css';


class UserDetails extends React.Component {
    constructor(props) {
        super(props); 
        this.props = props;   
        this.state = {
            mode: 'display',
            avatar:null,
            editable:false,
            user: props.user.details,
            history: props.history
        };
        if (this.state.user && this.state.user.EmailList) {
            this.user = cloneDeep (this.state.user);
            this.user.Emails = JSON.parse(this.state.user.EmailList);  
            this.user.Phones = JSON.parse(this.state.user.PhoneList);  
            this.state.user.Emails=cloneDeep(this.user.Emails);
            this.state.user.Phones=cloneDeep(this.user.Phones);
        }

        console.log('this.state and props in UserDetails');
        console.log(this.state);
        this.maxFileSize = 1000000;
        console.log(props); 
        if (props && props.location == undefined) {            
            this.state = {...this.state, mode: 'hidden' };
        }
        if (props && props.match && props.match.params.id) {           
            if (this.state.user.AccountId.toUpperCase() !== props.match.params.id.toUpperCase()){
                this.state = {...this.state, mode: 'hidden' };
            }
        } 
        if (props && props.location && props.location.pathname) {
            if (props.location.pathname !== '/profile')
            this.state = {...this.state, mode: 'hidden' };
        }
        this.handleSave = this.handleSave.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleFieldWithTypeChange = this.handleFieldWithTypeChange.bind(this);
        this.handleEditableStateChange = this.handleEditableStateChange.bind(this);
        this.onAddMethod = this.onAddMethod.bind(this); 
        this.onRemoveMethod = this.onRemoveMethod.bind(this); 
        this.handleMenu = this.handleMenu.bind(this);
        this.hanldeBlur = this.hanldeBlur.bind(this);
        this.checkUpload = this.checkUpload.bind(this);
        this.updateAvatar = this.updateAvatar.bind(this);
        this.refreshuser = this.refreshuser.bind(this);
        this.gotoLink = this.gotoLink.bind(this);
      }
onAddMethod(type){
    setAutoFreeze(false);
    if (type ==='phone') {
        const idx = this.state.user.Phones.length;
        const newItem = {
            Index: idx,
            Id: Helper.generateUUID(),
            PhoneNumber: "",
            PhoneType: ""
            };
        const nextState = produce(this.state, draftState => {
            draftState.user.Phones.push(newItem);
            });
        this.setState(nextState);
        this.user.Phones.push(newItem);
    }
    if (type ==='email') {  
        const idx = this.state.user.Emails.length;
        const newItem = {
            Index: idx,
            Id: Helper.generateUUID(),
            EmailAddress: "",
            EmailType: ""
            };
        const nextState = produce(this.state, draftState => {
            draftState.user.Emails.push(newItem);
        });
        this.setState(nextState);
        this.user.Emails.push(newItem);
    }   
}
onRemoveMethod(type,  id,  index){ 
    if (type ==='phone') {
        var Phones = this.state.user.Phones.filter((item) => {
            if (item.Id !== id) return item; 
        }); 
        const nextState = produce(this.state, draftState => {
            draftState.user.Phones = Phones;
            draftState.user.PhoneList = JSON.stringify(Phones);
        });
        this.user.Phones.splice(index);
        this.setState(nextState); 
    } 
    if (type ==='email') {
        var Emails = this.state.user.Emails.filter((item) => {
            if (item.Id !== id) return item; 
        }); 
        const nextState = produce(this.state, draftState => {
            draftState.user.Emails = Emails;
            draftState.user.EmailList = JSON.stringify(Emails);
        });
        this.user.Emails.splice(index);
        this.setState(nextState);
    } 
}
handleSave(){
    const user_info = this.state.user;
    if (typeof(this.props.editUser)==='function') { 
        this.props.editUser(user_info.AccountId, user_info);
        this.setState({ mode:'display', editable:false });
    }
    else {
        console.log('cannot save :( - no editUser function defined');
    }
    this.setState({ mode:'display', editable:false });
    this.refreshuser();
} 
handleEditableStateChange(editable) {
    if (editable) { 
        this.safeCopy = cloneDeep(this.state);
        this.setState({...this.state, 
            mode: editable ? 'edit' : 'display', 
            editable});
    }
    else {
        this.setState(this.safeCopy);
    }
}
handleDelete(){
    console.log('Delete');
}
handleFieldChange(e) {
    let field_id=e.target.id;
    let newSate={
    ...this.state, user:{ ...this.state.user, [field_id]: e.target.value}
    };
    this.setState(newSate);
}
handleCheckBox(type, id, e) {
    var item = {};
    var nextState;
    switch(type) {
        case 'phone':
            const phones = cloneDeep(this.state.user.Phones);
            item = phones.find(x => x.Id === id);
            item.inUse = e.target.value; 
            nextState = produce(this.state, draftState =>
                {draftState.user.Phones = phones});
            this.setState(nextState);         
            break; 
        case 'email':
            const emails = cloneDeep(this.state.user.Emails);
            item = emails.find(x => x.Id === id);
            item.inUse = e.target.value;
            nextState = produce(this.state, draftState =>
                {draftState.user.Emails = cloneDeep(emails)});
            this.setState(nextState);
            break; 
    }
}
handleFieldWithTypeChange(type, id, e) {
    var item; 
    switch(type) {
        case 'phone':
            item = this.user.Phones.find(x => x.Id === id);
            if (item) item.PhoneNumber = e.target.value;          
            break;
        case 'phoneType':
            item = this.user.Phones.find(x => x.Id === id);
            if (item) item.PhoneType = e.target.value;
            break;
        case 'email':
            item = this.user.Emails.find(x => x.Id === id);
            if (item) item.EmailAddress = e.target.value;
            break;
        case 'emailType':
            item = this.user.Emails.find(x => x.Id === id);
            if (item) item.EmailType = e.target.value;
            break;
    }
    const nextState = produce(this.state, draftState =>
        {
            draftState.user.Phones = cloneDeep(this.user.Phones);
            draftState.user.Emails = cloneDeep(this.user.Emails);
            draftState.user.EmailList = JSON.stringify(this.user.Emails);
            draftState.user.PhoneList = JSON.stringify(this.user.Phones);
        }
        );
    this.setState(nextState);
}
hanldeBlur(){
    /*
    const phones = cloneDeep(this.state.user.Phones);
    const emails = cloneDeep(this.state.user.Emails);
    const nextState = produce(this.state, draftState =>
        {
            draftState.user.Phones = phones;
            draftState.user.Emails = cloneDeep(emails);
        });
    this.setState(nextState);
    */
   let newSate={
    ...this.state, user:{ ...this.state.user, 
        EmailList: JSON.stringify(this.state.user.Emails),
        PhoneList: JSON.stringify(this.state.user.Phones)
    }
    }; 
    this.setState(newSate);
}

gotoLink(target){
    this.props.history.push(target);
} 
handleMenu(event) {
    event.preventDefault();
    event.stopPropagation();
    this.cm.show(event);
    console.log('handleMenu');
    return false;
}
checkUpload(e){
    console.log(e);
    if (e.size>this.maxFileSize){
        this.growl.show({severity: 'warn', summary: 'Image Upload Failed', detail: 'File size exceed 1Mb'});
    }
}
updateAvatar(){ 
    let newSate={
    ...this.state, user:{ ...this.state.user, Avatar: this.state.user.AccountId}
    };
    this.setState(newSate); 
    this.refreshuser();
}
refreshuser(){
    if (this.props.fetchUser) { this.props.fetchUser(this.state.user.AccountId); }
}
render() {
    const menu =[ 
        {
            label:'Edit',
            icon:'pi pi-fw pi-user-edit',
            command: () => {
                this.handleEditableStateChange(true);
                this.menu.hide();
            }
        },
        {
            label:'Change Avatar',
            icon:'pi pi-fw pi-camera',
            command: () => {
                this.upload.fileInput.click();
                this.menu.hide();
            }
         }, 
        {
           label:'Go Back',
           icon:'pi pi-fw pi-arrow-left',
           command:()=>{ window.location.hash="/"; }
        }
     ];
    let phoneTypes = [
        {label: 'Cell', value: 'Cell'},
        {label: 'Home', value: 'Home'},
        {label: 'Office', value: 'Office'},
        {label: 'Other', value: 'Other'}
    ];
    let emailTypes = [ 
        {label: 'Main', value: 'Main'},
        {label: 'Private', value: 'Private'},
        {label: 'Office', value: 'Office'},
        {label: 'Other', value: 'Other'}
    ];
    const ApiRoot = Config.baseUrl;
    const lp_wide = {minWidth:'8em',padding:'0.5em'}; 
    const fixedW = {width: '20em', float: 'left'};
    const floatLeft = {float: 'left'};
    const floatRight = {float: 'right'};
    var initial = '?';
    if (this.state.user && this.state.user.FirstName && this.state.user.FirstName.length>0){
        initial = this.state.user.FirstName.substring(0,1).toUpperCase();
    } 
    console.log('>>>>>  user Details');
    console.log(this.state);
     
    if (this.state.user && this.state.user.Avatar && this.state.avatar === null) {
        const ts = new Date().getTime();
        const avatarUrl = `${Config.baseUrl}/upload/userpic/${this.state.user.AccountId}?_ts=${ts}`;
        const avatar = { backgroundImage: `url(${avatarUrl})`};
        this.setState({avatar});
    } 
    return ( 
        <div> 
        <TieredMenu model={menu} popup={true} ref={el => this.menu = el} />

        {this.state.user.AccountId && this.state.mode === 'edit' &&
        <div>
            <div className="p-grid p-fluid">    
                <div className="p-col-12 p-md-3 p-xl-3">
                    {this.state.avatar && 
                    <div className="ma30px">
                        <div className="circle-avatar" style={this.state.avatar}></div>
                    </div>}
                    {this.state.avatar === null && 
		                <Initial letter={initial} id={this.state.user.AccountId}></Initial>
                    }
                </div>            
                <div className="p-col-12 p-md-9 p-xl-9">
                    <div className="hv-upload">
                    <FileUpload maxFileSize={this.maxFileSize} onUpload={(e) => this.updateAvatar()} 
                    onValidationFail = {(e) => this.checkUpload(e)} name="avatar" 
                    url={`${ApiRoot}/upload/?id=${this.state.user.AccountId}&aid=${this.state.user.AccountId}`} 
                    auto={true} mode="basic" />
                    </div>
                    <label className="lp-label">{this.state.message}</label>
                </div>    
                <div className="p-col-12 p-md-6 p-xl-6">
		            <label className="lp-label">First Name</label>
                    <InputText placeholder="FirstName" id="FirstName" 
                    readOnly={!this.state.editable}
                    onChange={this.handleFieldChange}
                    value={this.state.user.FirstName} />
                </div>
                <div className="p-col-12 p-md-6 p-xl-6">
		            <label className="lp-label">Last Name</label>
                    <InputText placeholder="LastName" id="LastName" 
                    readOnly={!this.state.editable}
                    onChange={this.handleFieldChange}
                    value={this.state.user.LastName} />
                </div>
                
                <div className="p-col-12 p-md-12 p-xl-12">
		            <label className="lp-label">Private notes</label>
                    <InputTextarea rows={2} cols={30} value={this.state.user.Comment} 
                    id="Comment" onChange={this.handleFieldChange} autoResize={true} />

                </div>

                <div className="p-col-12 p-md-6 p-xl-6"> 
		            <label className="lp-label">Contact Info</label>
                </div>

                <div className="p-col-12 p-md-6 p-xl-6"> 
                <div style={floatRight}>
                {this.state.user && this.state.user.Emails && this.state.user.Emails.length === 0 && 
                <span><a onClick={(e) => this.onAddMethod('email')}>Add email</a></span>
                }
                <span style={lp_wide}></span>
                {this.state.user && this.state.user.Phones && this.state.user.Phones.length === 0 && 
                <span><a onClick={(e) => this.onAddMethod('phone')}>Add phone</a></span>
                }
                </div>
                </div>
                
                {this.state.user && this.state.user.Emails && 
                    this.state.user.Emails.map((item, index) => 
                    <FieldWithType key={`fe_${index}`}
                        typeList={emailTypes} label={"Email"} index={index} id={item.Id}
                        placeholder={"Email"} value={item.EmailAddress} typeValue={item.EmailType} 
                        onAdd = {(e) => this.onAddMethod('email')}  dataType = {"email"}
                        onRemove = {() => this.onRemoveMethod('email', item.Id, index)} onBlur={this.hanldeBlur}
                        onFieldChange={(e)=> this.handleFieldWithTypeChange('email',item.Id, e)} 
                        onTypeChange = {(e)=> this.handleFieldWithTypeChange('emailType',item.Id, e)}>        
                    </FieldWithType>
                )}
                {this.state.user && this.state.user.Phones && 
                    this.state.user.Phones.map((item, index) => 
                    <FieldWithType  key={`fp_${index}`}
                        typeList={phoneTypes} label={"Phone"} index={index}
                        placeholder={"Phone"} value={item.PhoneNumber} typeValue={item.PhoneType} 
                        onAdd = {(e) => this.onAddMethod('phone')} dataType = {"phone"} onBlur={this.hanldeBlur}
                        onRemove = {() => this.onRemoveMethod('phone',item.Id, index)}
                        onFieldChange={(e)=> this.handleFieldWithTypeChange('phone',item.Id, e)} 
                        onTypeChange = {(e)=> this.handleFieldWithTypeChange('phoneType',item.Id, e)}>       
                    </FieldWithType>
                )}

                <div className="p-col-12 p-md-12 p-xl-12">
                    <InputText placeholder="Address"  value={this.state.user.Address}  
                    id="Address" onChange={this.handleFieldChange} />
                </div>

                </div>
                <div className="ui-g">  
                    {this.state.editable &&
                    <Button className="p-button-raised p-button-info mr1px"
                        style={lp_wide} label="Save and Close" onClick={this.handleSave} />
                    }
                    {/* <LinkButton className="p-button-raised p-button-secondary mr1px"
                        to='/users' style={lp_wide} label="Cancel" /> */}
                    
                    <Button className="p-button-raised p-button-secondary mr1px"
                        style={lp_wide} label="Cancel" onClick={()=>this.handleEditableStateChange(false)} />
                    {/* <Button className="p-button-raised p-button-secondary mr1px"
                        style={lp_wide} label="Delete" onClick={this.handleDelete} /> */}
                </div>
            </div>
         } 
        {this.state.user.AccountId && this.state.mode === 'display' &&
         <div>
            <div className="hv-noshow">
            <FileUpload maxFileSize={this.maxFileSize} onUpload={(e) => this.updateAvatar()}  ref={el => this.upload = el} 
            onValidationFail = {(e) => this.checkUpload(e)} name="image" 
            url={`${ApiRoot}/upload/?id=${this.state.user.AccountId}&aid=${this.state.user.AccountId}`} 
            auto={true} mode="basic" />
            </div>
         <div className="p-grid p-fluid">    
            <div className="p-col-12 p-md-3 p-xl-3">
                {this.state.avatar && 
                <div className="ma30px" >
                    <div className="circle-avatar" style={this.state.avatar}></div>
                </div>}
                {this.state.avatar === null && 
                    <Initial letter={initial} id={this.state.user.AccountId}></Initial>
                }
                <label className="lp-label">{this.state.message}</label>
                <Button icon="pi pi-ellipsis-v" style={floatLeft} 
                onClick={(event) => this.menu.toggle(event)}
                className="p-button p-button-rounded p-button-secondary mr1px hidden-xs-up hv-float-btn"/>
            </div>   
            <div className="p-col-12 p-md-9 p-xl-9 hidden-xs-down">
            <Button icon="pi pi-ellipsis-v" style={floatLeft} 
                onClick={(event) => this.menu.toggle(event)}                
                style={{margin:'3em'}}
                className="p-button p-button-rounded p-button-secondary mr1px"/>
            </div>         
            <div className="p-col-12 p-md-12 p-xl-12">
                 <label className="lp-label"><b>{this.state.user.FirstName} {this.state.user.LastName}</b></label>   
             </div>  
             {this.state.user.Comment &&
                <div className="p-col-12 p-md-12 p-xl-12">
                    <label className="lp-label">{this.state.user.Comment}</label>   
                </div> 
            }
            {this.state.user.Address &&
             <div className="p-col-12 p-md-12 p-xl-12">
                    <label className="lp-label">{this.state.user.Address}</label>
             </div>
            }
             {this.state.user && this.state.user.Emails && this.state.user.Emails.map((item, index) => (
             <div className="p-col-12 p-md-12 p-xl-12" key={`re_${index}`}>
                 <div style={fixedW}>
                 <label  key={`le_${index}`} className="lp-label">{item.EmailAddress} ({item.EmailType})</label>
                 </div>          
             </div>
             ))}

            {this.state.user && this.state.user.Phones && this.state.user.Phones.map((item, index) => (
             <div className="p-col-12 p-md-12 p-xl-12" key={`re_${index}`}>
                 <div style={fixedW}>
                 <label  key={`lp_${index}`} className="lp-label">{item.PhoneNumber} ({item.PhoneType})</label>
                 </div> 
             </div>
             ))}
            <div className="p-col-12 p-md-12 p-xl-12">
            </div> 
              
         </div>
        <div> 
            <Button className="p-button-raised p-button-secondary hidden-xs-down mr1px"
                style={lp_wide} label="Edit" onClick={()=>this.handleEditableStateChange(true)} />
            <LinkButton className="p-button-raised p-button-primary mr1px"
                to='/' style={lp_wide} label="Close" />
        </div>
        <Growl ref={(el) => this.growl = el} />
     </div>  
     
        }
        </div>  
    );
    }

}

export default UserDetails;

