import { connect } from 'react-redux'
//import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import Header from './Header.jsx'

const mapStateToProps = (state, props) => {
  console.log('mapStatein HeaderCTX');
  console.log(state);
  return {
    user: state.user,
    store: props.store
  }
}

const HeaderCTX = withRouter(connect(mapStateToProps)(Header))

export default HeaderCTX
