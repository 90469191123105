import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import {enableAllPlugins} from 'immer';
import {AppTopbar} from './AppTopbar';
import {AppFooter} from './AppFooter';
import {AppMenu} from './AppMenu';
import {AppProfile} from './AppProfile';
import  Login from "./view/containers/Users/LoginCTX"; 
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css'; 
import Nav from './view/components/Nav';

import './layout/layout.scss';
import './App.scss';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            history: props.history,
            store: props.store, 
            user: props.user
        };

        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.createMenu();
    }

    onWrapperClick(event) {
    //    console.log(event);
    // not sure why it was like that.. it cause page regersh on any clikc inside text boxes etc
    // if (!this.menuClick) {
    //     this.setState({
    //         overlayMenuActive: false,
    //         mobileMenuActive: false,
    //     })
    // }
    // console.log('onWrapperClick');
    //     this.menuClick = false;
    }

    onToggleMenu(event) {
        this.menuClick = true;

        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            }
            else if (this.state.layoutMode === 'static') {
                this.setState({
                    staticMenuInactive: !this.state.staticMenuInactive
                });
            }
        }
        else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({
                mobileMenuActive: !mobileMenuActive
            });
        }
       
        event.preventDefault();
    }

    onSidebarClick(event) {
        this.menuClick = true;
    }

    onMenuItemClick(event) {
        if(!event.item.items) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            })
        }
    }

    createMenu() {
        this.menu = [
            // {label: 'Dashboard', icon: 'pi pi-fw pi-home', command: () => {window.location = '#/'}},
            {label: 'Contacts', icon: 'pi pi-fw pi-users', command: () => {window.location = '#/contacts'}},
            {label: 'Account', icon: 'pi pi-fw pi-user', command: () => {window.location = '#/profile'}},
            {label: 'Logout', icon: 'pi pi-fw pi-power-off', command: () => {window.location = '/'}}
            // {label: 'Settings', icon: 'pi pi-fw pi-cog', command: () => {window.location = '#/settings'}},
            // {label: 'Documentation', icon: 'pi pi-fw pi-question', command: () => {window.location = "#/documentation"}}
        ];
    }
 
    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            store: nextProps.store,
            user: nextProps.user
        });
    }
    componentDidUpdate() {
        if (this.state.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    }
 
    render() {
        console.log('rendering main app');
        console.log(`public root: ${process.env.PUBLIC_URL}`);
        // const logo = process.env.PUBLIC_URL + this.state.layoutColorMode === 'dark' 
        // ? '/public/assets/layout/images/logo.png'
        // : '/public/assets/layout/images/logo.svg';

        const logo = process.env.PUBLIC_URL + '/assets/layout/images/logo.png';

        const wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive
        });

        const sidebarClassName = classNames("layout-sidebar", {
            'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
            'layout-sidebar-light': this.state.layoutColorMode === 'light'
        });
        let LoggedIn = false;
        let status = ''; 
        const {user, history} = this.state;
        // console.log(JSON.stringify(user));
        if (user && user.details ) {
            LoggedIn = (user.details.JwtToken !== undefined);
            status = user.details.Status; 
        }
       
        //console.log('App: Logged in: ' + LoggedIn.toString());  
        const params = {
            view:'login'
        }
        const passThrough ={user, history};
        return (
            <div>
                { !LoggedIn && <div> 
                    <Login props={params} store={this.state.store} />{status}</div> }
                { LoggedIn &&
            <div className={wrapperClass} onClick={this.onWrapperClick}> 
                <AppTopbar onToggleMenu={this.onToggleMenu}/>

                <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>
                    <div className="layout-logo">
                        <img alt="Logo" style={{width:'200px'}} src={logo} />
                    </div>
                    <AppProfile {...passThrough}/>
                    <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} />
                </div>

                <div className="layout-main">
                    <div className="App">  
                        <Nav/> 
                    </div>
                </div>

                <AppFooter />

                <div className="layout-mask"></div>
            </div>
                }

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default withRouter(connect(mapStateToProps)(App))
