import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'; 
import { HashRouter } from 'react-router-dom';
import { createHashHistory } from 'history';
import './index.css';
import './assets/prime/primeicons.css'
import 'font-awesome/css/font-awesome.min.css';
import store from './store';
import App from './App';
import routes from './router/routes';
  
let initialState = {routing:'/'};

// rehydrate initialState for JS app
if (window.__INITIAL_STATE__) {
    initialState = window.__INITIAL_STATE__;
}
const hashHistory = createHashHistory();  
let AppStore  = store(initialState, hashHistory);
window.store = AppStore;
window.environment = process.env.NODE_ENV;
ReactDOM.render(
    <Provider store={AppStore}>
        <HashRouter>
            <div>
                <App store={AppStore} routes={routes} history={hashHistory}/>
            </div>
        </HashRouter>,
    </Provider>
    ,document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();