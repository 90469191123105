import React from 'react'
import { Link } from 'react-router-dom'; 
import { DataView } from 'primereact/components/dataview/DataView';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/components/button/Button';
import Initial  from '../../components/Elements/Initial';
import InputWithClear from '../Common/InputWithClear';
import Config from '../../../config';
import * as Helper from '../../components/Helper'; 
 
export default class ListPatientsInline extends React.Component {
  constructor (props) { 
    super(props);
    this.props = props;
    this.defaultPageSize = 10;
    this.state = {
      info: (props.user && props.user.info) ?  props.user.info : {isMobile: false},
      contact_list:[], 
      totalRecords:0, 
      first:0, 
      page:0, 
      rows: this.defaultPageSize,
      query: '', 
      loading: false,
      sortField: 'LastCall',
      sortOrder: 'desc' } 
    if (props.contact_list && props.contact_list.pageNumber) { 
      this.state.contact_list = props.contact_list.list || [];
      this.state.totalRecords = props.contact_list.totalRecords || 0; 
      this.state.page = props.contact_list.pageNumber -1 || 0;
      this.state.rows = props.contact_list.pageSize || this.defaultPageSize;
      // if (this.state.totalRecords < props.contact_list.pageSize ) {
      //   this.state.rows = this.state.totalRecords; 
      // }
      // if (this.state.contact_list.length < props.contact_list.pageSize ) { 
      //   this.state.rows = this.state.contact_list.length;
      // }
      this.state.first =(this.state.page ) * this.defaultPageSize;
      this.state.query = props.contact_list.query || '';
      this.state.sortField = props.contact_list.sortBy || 'LastCall';
      this.state.sortOrder = props.contact_list.sortOrder ||'desc';
      if (this.state.sortField === 'LastName') {
        this.state.sortKey = this.state.sortOrder;
      }
      else {
        this.state.sortKey = 'lastcall';
      }
    }
    this.query='';     
    this.onPage = this.onPage.bind(this);
    this.setActionButton = this.setActionButton.bind(this);     
    this.rowTemplate = this.rowTemplate.bind(this);
    this.callCreateNew = this.callCreateNew.bind(this);
    this.callViewDetails = this.callViewDetails.bind(this);
    this.makeCall = this.makeCall.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
  }
  componentDidMount() {  
    if (this.props.gotContacts === false ) { 
        if (this.props.fetchContacts) {           
          this.props.fetchContacts(1,this.defaultPageSize,'LastCall_desc','');
        }
    } 
}
  handleSearch(e) {  
    this.setState({ query: e.target.value}); 
    this.props.fetchContacts(0, this.defaultPageSize,
      `${this.state.sortField}_${this.state.sortOrder}`, e.target.value);
  }

  rowTemplate(contact) {
    if(!contact) {
      console.log('no contact');
        return null;
    }
    var avatar = null;
    if (contact.Avatar) {
        avatar = { backgroundImage: `url(${contact.Avatar})`};
    }
    if (contact.Avatar) {
      const ts = new Date().getTime();
      const avatarUrl = `${Config.baseUrl}/upload/logo/${contact.Id}?_ts=${ts}`;
      avatar = { backgroundImage: `url(${avatarUrl})`};
    }
    var emails = JSON.parse(contact.EmailList); 
    emails = emails.filter(x=>x.inUse).map(x=>x.EmailAddress).join();
    var phones = JSON.parse(contact.PhoneList);
    phones = phones.filter(x=>x.inUse).map(x=>x.PhoneNumber).join();
    // if (!emails) emails='';
    // if (!phones) phones='';
    return (
      <>
        {this.state && !this.state.info.isMobile &&
          <div className="row-details p-grid"> 
                <div className="p-col-1">
                {avatar && 
                    <div className=""style={{width:'3em'}}>
                        <div className="circle-avatar" style={avatar}></div>
                    </div>}
                    {avatar === null &&
                      <Initial letter={contact.FirstName.substring(0,1).toUpperCase()} id={contact.Id}
                        small={true}></Initial>
                    } 
                  </div>
                <div className="p-col-6"><b>{contact.FirstName} {contact.LastName}</b>
                <br/>{emails}<br/>{phones}
                </div>
                {/* {emails !== '' && phones !== '' && 
                  <div className="p-col-2">{emails}<br/>{phones}</div> 
                }
                {emails === '' || phones === '' && 
                  <div className="p-col-2 row-cell">{emails}{phones}</div> 
                }
                {contact.EmailList ==='[]' && contact.PhoneList === '[]' && 
                  <div className="p-col-2 row-cell"></div> 
                } */}
                <div className="p-col-2"> 
                  {this.setCallButton(`${contact.Id}`,phones,emails)}
                </div>
                <div className="p-col-2 row-cell">
                  {this.setActionButton(`${contact.Id}`)}
                </div>

                <div className="p-col-1"></div>
          </div>
        }
        {this.state && this.state.info.isMobile &&
          <div className="row-details p-grid"> 
                <div className="p-col-7 row-cell"><b>{contact.FirstName} {contact.LastName}</b>
                <br/>{emails}<br/>{phones}
                </div>
                  
                <div className="p-col-2 row-cell"> 
                  <Link to={`/contacts/${contact.Id}`}>
                    <i className="pi pi-pencil" style={{'fontSize': '1.2em'}}></i>
                  </Link>
                </div>               
                <div className="p-col-2 row-cell">
                   <a target="blank" href={`https://videochat.claimmanager.ca:8443/?id=${contact.Id}`}>
                  <i className="pi pi-video" style={{'fontSize': '1.2em'}}></i></a>
                 </div> 
                 <div className="p-col-1"></div>
          </div>
        }
      </>
    );
}
  setActionButton(id) {    
    return (<div>
      <a target="blank" onClick={()=>this.callViewDetails(`${id}`)}>
      View</a>
      </div>); 
  }
  setCallButton(id, emails, phones) {  
    if (emails.length ===0 && phones.length === 0) {
      return (<Button className="p-button-raised p-button-info mr1px hv-btn-fixed" disabled={true}
      title="Email address or phone number required to start call."
      label="Invite" />)
    }
    return (<Button className="p-button-raised p-button-info mr1px hv-btn-fixed"
    title="Start the call. Invitation will be sent to default email address and/or phone number."
          label="Invite" onClick={()=>this.makeCall(`${id}`)} />)
  }

  makeCall(id){
    const guest = Helper.getItemFromArray(this.state.contact_list,id,'Id');
    const roomName = id.substring(0,8).toUpperCase();
    this.props.inviteContact(id,guest);
    window.open(`${Config.videoUrl}?id=${roomName}`, "_blank");
  }
  callCreateNew() {
    this.props.cleanCurrent();
    this.props.history.push('/contacts/add');
  }
  callViewDetails(id) {
    const item = Helper.getItemFromArray(this.state.contact_list,id,'Id');
    this.props.setCurrent(item);
    this.props.history.push(`/contacts/${id}`);
  }
  onPage(event) {
    this.setState({
        loading: true
    });

    const startIndex = 1 + event.first/this.defaultPageSize; 
    this.props.fetchContacts(startIndex, this.defaultPageSize,
      `${this.state.sortField}_${this.state.sortOrder}`, this.state.query);

  }
  onSortChange(event) { 

    this.setState({sortKey:event.value});
    if (event.value==='lastcall') {
      this.props.fetchContacts(1, this.defaultPageSize, 'LastCall_desc', this.state.query);
    }
    else {
      this.props.fetchContacts(1, this.defaultPageSize, `LastName_${event.value}`, this.state.query);
    }
  }
  render () {
    const sortOptions = [
      {label: 'Recent', value: 'lastcall'},
      {label: 'Last Name A-Z', value: 'asc'},
      {label: 'Last Name Z-A', value: 'desc'},
    ];
    
    const header = (
      <Dropdown className="paginator-child" options={sortOptions} style={{position:'absolute'}}
      value={this.state.sortKey} placeholder="Sort By" onChange={this.onSortChange} />
    );
    
    return (
      <div>
        <div className="p-inputgroup" style={{marginBottom: '1em'}}> 
        <span className="p-inputgroup-addon" style={{width:'13em'}}>
              <i className="pi pi-search"></i>
              <span style={{paddingLeft:'0.4em'}}>Find contact</span>
          </span>
         <InputWithClear className="hv-fit-1btn mr1px" 
           value={this.state.query}  
           onChange={(e) => this.handleSearch(e)}/>  

        <Button style={{whiteSpace: 'nowrap', paddingLeft:'0.7em'}} icon="pi pi-user-plus" label=" Add New "  className="p-button mr1px" onClick={()=> this.callCreateNew()}/>
        </div>  

        {this.state.contact_list &&  this.state.contact_list.map && 
        <div>
            {header}
            <DataView value={this.state.contact_list} layout="list" itemTemplate={this.rowTemplate}
            paginator={true} rows={this.state.rows} first={this.state.first} 
            sortOrder={this.state.sortOrder} sortField={this.state.sortField}
            // header={header}  
            lazy  totalRecords={this.state.totalRecords} paginatorPosition={'top'}
            onPage={this.onPage} >
              {/* loading={this.state.loading} */}
            </DataView>
        </div>
        }
      </div>)
  }
}
