import React from 'react';
import Layout from '../components/Layout/Layout';
import ErrorBoundary from '../components/ErrorBoundary';
class About extends React.Component {

  componentDidMount() {
    //document.title = 'About Us';
  }

  render() {
    return (
      <ErrorBoundary>
      <Layout>
        <h1 className="mdl-typography--title">About Us</h1>
        <p className="mdl-typography--body-1">
          We are the best!!
        </p>
      </Layout>
      </ErrorBoundary>
    );
  }

}

export default About;
