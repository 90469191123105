
import {   FETCH_USER_SUCCESS, FETCH_USER_FAILURE,
    CREATE_USER_SUCCESS, CREATE_USER_FAILURE,
    LOGIN_SUCCESS, LOGIN_FAILURE, DEVICE_INFO_RESPONSE,
    FETCH_PLAN_HISTORY_SUCCESS, FETCH_PLAN_HISTORY_FAILURE, 
    FETCH_USER_LOGS_SUCCESS, FETCH_USER_LOGS_FAILURE,
    SWITCH_PLAN_SUCCESS, SWITCH_PLAN_FAILURE, SWITCH_PLAN_INFO
} from '../../actions/actionTypes'
const userReducer = (state, action) => {
    // TODO: Add action handlers (aka "reduces")
    console.log('user');
    console.log(action);
    if (state === undefined) state={};
    const { type, result } = action;
    // const result = results ? results.data : {};
    switch (action.type) {
    case 'COUNT':
        return { ...state, count: (state.count || 0) + 1 };
    case LOGIN_SUCCESS:
    case FETCH_USER_SUCCESS:
    case CREATE_USER_SUCCESS:
        return {...state, details: result, error: undefined}
    case FETCH_USER_FAILURE:
    case CREATE_USER_FAILURE:
    case LOGIN_FAILURE:
        return {...state, details: {}, error: result}
    case DEVICE_INFO_RESPONSE:
        return {...state, info: result}
    case SWITCH_PLAN_INFO:
        return {...state, wizard: result}  
    case FETCH_PLAN_HISTORY_SUCCESS:
        return {...state, planChanges: result}
    case SWITCH_PLAN_SUCCESS:
        return {...state, planChanges: result}
    case FETCH_USER_LOGS_SUCCESS:
        return {...state, logs: result}
    case FETCH_PLAN_HISTORY_FAILURE:
        return {...state, planChanges: []}
    case FETCH_USER_LOGS_FAILURE:
        return {...state, logs:[]}
    // case SWITCH_PLAN_FAILURE:
    //   return {...state, planChanges: []}
    default:
        return state;
    }
};

export default userReducer;
