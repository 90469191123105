
import Base64 from 'base-64';

export function getItemFromArray(arr, id, field) {
    var found = null;
    if (id === undefined) return found;
    if (arr === undefined || arr == null) return found;
    if (arr.length === undefined) arr = [arr];
    arr.forEach(element => {
        if (element !== undefined) {
            if (element.id === id) {
                found = element;
            }
            if (element.Value !== undefined) {
                if (element.Value.toLowerCase() === id.toLowerCase()) {
                    found = element;
                }
            }
            if (element.CodeGuid !== undefined) {
                if (element.CodeGuid.toLowerCase() === id.toLowerCase()) {
                    found = element;
                }
            }
            if (field !== undefined && element[field] !== undefined) {
                var item_id = element[field];
                if (typeof (id) === 'number' && typeof (item_id) === 'string' && isNumeric(item_id)) {
                    item_id = parseInt(item_id, 10);
                }
                if (typeof (item_id) === 'number') {
                    if (item_id === id) {
                        found = element;
                    }
                }
                else {
                    if (item_id.toLowerCase() === id.toLowerCase()) {
                        found = element;
                    }
                }
            }
        }
    });
    return found;
}
export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}
export function encoded4API(text) {
    let reply = Base64.encode(text);
    if (reply.endsWith('=')== false) reply+='===';
    return reply
}
export function generateUUID() {  
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}
export function isMobileDevice() {
    return !!(/Android|webOS|iPhone|iPad|iPod|BB10|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(navigator.userAgent || ''));
}