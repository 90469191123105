import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import user from './user';
import contact from './contact';
import clinic from './clinic';
import billing from './billing';

export default combineReducers({ 
    routing,
    user,
    contact,
    clinic,
    billing
});
