import {FETCH_PLANS_SUCCESS,FETCH_PLANS_FAILURE,
    EDIT_PLAN_REQUEST,EDIT_PLAN_SUCCESS,EDIT_PLAN_FAILURE,
    FETCH_PLAN_REQUEST,FETCH_PLAN_SUCCESS,FETCH_PLAN_FAILURE,
    FETCH_CARDS_SUCCESS,FETCH_CARDS_FAILURE,
    CREATE_CARD_SUCCESS,CREATE_CARD_FAILURE,
    EDIT_CARD_SUCCESS, CLEAR_CARD_ERROR,
    DELETE_CARD_SUCCESS,DELETE_CARD_FAILURE }  
    from '../../actions/actionTypes';
const billingReducer = (state, action) => {
    if (state === undefined) state={};
    const { type, result, error } = action;
    console.log(`billingReducer: ${type}`)
    switch (type) { 
    case FETCH_PLANS_SUCCESS: 
        return {...state, list: result} 
    case FETCH_PLAN_FAILURE: 
        return {...state, list: []} 
    case EDIT_PLAN_SUCCESS:
    case FETCH_PLAN_SUCCESS: 
        return {...state, details: result} 
    case EDIT_PLAN_FAILURE:
    case FETCH_PLAN_FAILURE: 
        return {...state, details:{} }
    case CREATE_CARD_SUCCESS:
        // case EDIT_CARD_SUCCESS:
    case DELETE_CARD_SUCCESS:
    case FETCH_CARDS_SUCCESS:
		  return {...state, cardList: result, card: undefined}
    case CREATE_CARD_FAILURE:
        return {...state, cardError: error, card: result.card}
        // case CREATE_CARD_FAILURE:
        // case DELETE_CARD_FAILURE:
        // case FETCH_CARDS_FAILURE:
        //     return {...state, cardList: []}
    case CLEAR_CARD_ERROR:
        return {...state, cardError: undefined}
    default:
        return state;
    }
};
export default billingReducer; 

