import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Button } from 'primereact/components/button/Button';

const LinkButton = (props) => {
  const {
    history,
    location,
    match,
    staticContext,
    to,
    onClick,
    // ⬆ filtering out props that `button` doesn’t know what to do with.
    ...rest
  } = props;
  const lp_wide = {minWidth:'8em',padding:'0.5em'};
  return (
    <Button className="ui-button ui-widget ui-state-default ui-corner-all"
    style={lp_wide} 
      {...rest} // `children` is just another prop!
      onClick={(event) => {
        onClick && onClick(event);
        history.push(to)
      }}
    />
  )
}
LinkButton.propTypes = {
  to: PropTypes.string.isRequired
  //,children: PropTypes.node.isRequired
}

export default withRouter(LinkButton)