import axios from 'axios';
import Config from '../config';

const start2FA = (user_info) => { 
    return axios.post(Config.apibase + 'users/start2fa', user_info)
    .then((results)=> { 
        return results.data;
    })
    .catch(reason=>{ 
        console.log('error starting 2FA flow ' + JSON.stringify(reason));
        return {error: reason};
    });
}
const verify2FA = (user_info) => { 
    return axios.post(Config.apibase + 'users/verify2fa', user_info)
    .then((results)=> {
        return results.data;
    })
    .catch(reason=>{
        console.log('error verifying 2FA code ' + JSON.stringify(reason));
        return {error: reason};
    });
}
const activate = (code) => { 
    return axios.post(Config.apibase + 'users/activate', code)
    .then((results)=> {
        return results.data;
    })
    .catch(reason=>{
        console.log('error while activating account' + JSON.stringify(reason));
        return {error: reason};
    });
}
const register = (user_info) => {
    return axios.post(Config.apibase + '/users/register', user_info)
        .then((results)=> { 
            return results.data;
        })
        .catch(reason=>{
            console.log('error trying to register' + JSON.stringify(reason));
            return {error: reason};
        })
}
const TwoFactorService = {
    start2FA,
    verify2FA,
    register,
    activate
}
export default TwoFactorService;