import fetch from 'isomorphic-fetch';
import { thunkCreator } from './utils';
import baseUrl from '../config';
// import {CREATE_CLAIM_REQUEST,CREATE_CLAIM_SUCCESS,CREATE_CLAIM_FAILURE,EDIT_CLAIM_REQUEST,EDIT_CLAIM_SUCCESS,EDIT_CLAIM_FAILURE,FETCH_CLAIM_REQUEST,FETCH_CLAIM_SUCCESS,FETCH_CLAIM_FAILURE,DELETE_CLAIM_REQUEST,DELETE_CLAIM_SUCCESS,DELETE_CLAIM_FAILURE} from './actionTypes';

// export const startChat = (token, claim) => thunkCreator({
// 	 types: [CREATE_CLAIM_REQUEST,CREATE_CLAIM_SUCCESS,CREATE_CLAIM_FAILURE],
// 	 promise: fetch(baseUrl + 'claim', {
// 	 method: 'POST',
// 	 headers: {
// 	 'Accept': 'application/json',
// 	 'Content-Type': 'application/json',
// 	 'Authorization': `Bearer ${token}` },
// 	 body: JSON.stringify(claim)
// 	 }) 
// 	 .then(response => response.json()) 
// 	 .catch(err=> { 
// 	 	return new Promise((resolve,reject)=>{ 
// 	 		reject('error' + err.message); 
// 	 		}); 
// 	 	}) 
// }) 
// export const endChat = (token, id, claim) => thunkCreator({
// 	 types: [EDIT_CLAIM_REQUEST,EDIT_CLAIM_SUCCESS,EDIT_CLAIM_FAILURE],
// 	 promise: fetch(baseUrl + 'claim`/${id}`', {
// 	 method: 'PUT',
// 	 headers: {
// 	 'Accept': 'application/json',
// 	 'Content-Type': 'application/json',
// 	 'Authorization': `Bearer ${token}` },
// 	 body: JSON.stringify(claim)
// 	 }) 
// 	 .then(response => response.json()) 
// 	 .catch(err=> { 
// 	 	return new Promise((resolve,reject)=>{ 
// 	 		reject('error' + err.message); 
// 	 		}); 
// 	 	}) 
// })  

export const startChat = (token, claim) =>{}
export const endChat = (token, id, claim) =>{}