import axios from 'axios';
import qs from 'qs'; 
import jwtDecode from 'jwt-decode';
import Config from '../config';
import * as actions from './actionTypes'
const setAuthHeader = (state) => {
    const currentState = state();
    const token = currentState && currentState.user && currentState.user.details ? currentState.user.details.JwtToken : '';
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
const getAccountId = (state) => {
    const currentState = state();
    return currentState && currentState.user && currentState.user.details ? currentState.user.details.AccountID : '';
  
}

const formatError = (type, error) => {
    return {
        type,
        result: error, 
        results: error.response ? error.response.status : 500
    }
}

const dispatchError = (type, error) => {
    return {
        type,
        results: error
    }
}

const formatList = (results, type) => {
    return {
        type,
        result: results.data
    }
};

const formatDetails = (results) => {
    console.log('format_details');
    console.log(results);
    return {
        type: actions.FETCH_USER_SUCCESS,
        result: results.data
    }
};

export const register = (user_info) => (dispatch) => {
    axios.post(`${Config.apibase}/users/register`, user_info)
        .then((results)=> {
            const decoded = jwtDecode( results.data.JwtToken);
            results.data.decoded = decoded;
            return dispatch(formatDetails(results));
            // return dispatch(
            //     {
            //         type: actions.REGISTER_SUCCESS,
            //         results: {status:'Successfully created account. Please login.'}
            //     });
        })
        .catch(reason=>{
            console.log('error trying to register' + JSON.stringify(reason));
            return dispatch(formatError(actions.LOGIN_FAILURE, reason));
        })
}
export const login = (username, password) => (dispatch) => {
    // if(username === "demo") {
    //   return dispatch(
    //     {
    //       type: actions.LOGIN_SUCCESS,
    //       result: { response: 
    //         { 
    //           Id: '97424C8C-DEF2-42B3-8F54-7C422913B570',
    //           AccountId: '97424C8C-DEF2-42B3-8F54-7C422913B570',
    //           FirstName: 'Dorothy',
    //           LastName: 'Ozborn',
    //           Email: 'dorothy@secure.video',
    //           Phone: '604-211-9090',
    //           LoginName: "demo",
    //           FullName: "Dorothy Ozborn", 
    //           token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhYWEiLCJuYW1lIjoidGVzdGVyIn0.875yPolZAiDEh2dETz50pNw37wGA4ltEAHC9E4prmLI"
    //         }
    //       }
    //     }
    //   );
    // }

    axios.post(Config.apibase + 'users/login', qs.stringify({
        LoginName:username, password
    }))
        .then((results)=> { 
            // getAllUsers( results.data.DCNGuid,  results.data.JwtToken)(dispatch);
            if (results.data && results.data.JwtToken) {
                const decoded = jwtDecode( results.data.JwtToken);
                results.data.decoded = decoded;
                return dispatch(formatDetails(results));
            }
            return dispatch(formatError(actions.LOGIN_FAILURE, "Could not find your Hush Video account."));
        })
        .catch(reason=>{
            console.log('error trying to login' + JSON.stringify(reason));
            return dispatch(formatError(actions.LOGIN_FAILURE, reason));
        })
}

//TODO: implement
export const fetchSettings = () => (dispatch) =>{
    const result = {
        type: actions.FETCH_USER_SUCCESS,
        results: null
    }
    return dispatch(result);
}
export const logout = () => (dispatch) => {
    const result = {
        type: actions.LOGOUT_SUCCESS,
        results: null
    }
    return dispatch(result);
}

export const editUser = (id, userInfo) => (dispatch, state) => {
    setAuthHeader(state);
    const url=Config.apibase + `users/edit/${id}`;
    axios.put(url, userInfo)
        .then((results)=> { 
            return dispatch(formatDetails(results));
        })
        .catch(reason=>{
            console.log('error fetching patients: ' + JSON.stringify(reason));
            return dispatchError(actions.EDIT_USER_FAILURE,reason);
        })
};
export const removeMethod = (accountId, userInfo) => (dispatch, state) => {
    setAuthHeader(state);
    const url=Config.apibase + `users/methods/${accountId}`;
    axios.put(url, userInfo)
        .then((results)=> { 
            return dispatch(formatDetails(results));
        })
        .catch(reason=>{
            console.log('error removing users email or phone: ' + JSON.stringify(reason));
            return dispatchError(actions.EDIT_USER_FAILURE,reason);
        })
};

export const fetchUser = (userId) => (dispatch, state) => {
    setAuthHeader(state);
    // userId=getAccountId(state);
    const url=Config.apibase + `users/details/${userId}`;
    axios.get(url)
        .then((results)=> { 
            return dispatch(formatDetails(results));
        })
        .catch(reason=>{
            console.log('error fetching patients: ' + JSON.stringify(reason));
            return dispatchError(actions.FETCH_USER_FAILURE,reason);
        })
};

export const fetchPlanHistory = (userId) => (dispatch, state) => {
    setAuthHeader(state);
    const url=Config.apibase + `users/subscriptions/${userId}`;
    axios.get(url)
        .then((results)=> { 
            return dispatch(formatList(results, actions.FETCH_PLAN_HISTORY_SUCCESS));
        })
        .catch(reason=>{
            console.log('error fetching patients: ' + JSON.stringify(reason));
            return dispatchError(actions.FETCH_PLANS_FAILURE,reason);
        })
};

export const fetchUserHistory = (userId, page, pageSize) => (dispatch, state) => {
    setAuthHeader(state);
    const url=Config.apibase + `users/logs/${userId}/${page}/${pageSize}`;
    axios.get(url)
        .then((results)=> { 
            return dispatch(formatList(results, actions.FETCH_USER_LOGS_SUCCESS));
        })
        .catch(reason=>{
            console.log('error fetching patients: ' + JSON.stringify(reason));
            return dispatchError(actions.FETCH_USER_LOGS_FAILURE,reason);
        })
};

export const switchPlan = (id, plan_info) => (dispatch,state) => {
    setAuthHeader(state);
    axios.post(Config.apibase + `users/switchplan`, plan_info)
        .then((results)=> {
            return dispatch(formatList(results, actions.SWITCH_PLAN_SUCCESS));
        })
        .catch(reason=>{
            console.log('error switching ' + JSON.stringify(reason));
            return dispatchError(actions.SWITCH_PLAN_FAILURE, reason);
        })
}

export const sendCode = (user_info) => (dispatch,state) => {
    setAuthHeader(state);
    axios.post(Config.apibase + `users/sendcode`, user_info)
        .then((results)=> {
            return dispatch(formatList(results, actions.SWITCH_PLAN_SUCCESS));
        })
        .catch(reason=>{
            console.log('error switching ' + JSON.stringify(reason));
            return dispatchError(actions.SWITCH_PLAN_FAILURE, reason);
        })
}

export const verifyCode = (user_info) => (dispatch,state) => {
    setAuthHeader(state);
    axios.post(Config.apibase + `users/verifycode`, user_info)
        .then((results)=> {
            return dispatch(formatList(results, actions.SWITCH_PLAN_SUCCESS));
        })
        .catch(reason=>{
            console.log('error switching ' + JSON.stringify(reason));
            return dispatchError(actions.SWITCH_PLAN_FAILURE, reason);
        })
}

export const getDeviceInfo = (info) => (dispatch,state) => {
    dispatch({type: actions.DEVICE_INFO_RESPONSE, result: info })
}
export const setSwitchPlan = (info) => (dispatch) =>{
    dispatch({type: actions.SWITCH_PLAN_INFO, result: info});
}