
import {   FETCH_CLINIC_SUCCESS, FETCH_CLINIC_FAILURE,
    CREATE_CLINIC_SUCCESS, CREATE_CLINIC_FAILURE,
    LOGIN_SUCCESS, LOGIN_FAILURE } from '../../actions/actionTypes'
const clinicReducer = (state, action) => { 
    if (state === undefined) state={};
    const { type, result } = action;
    switch (action.type) {
    case FETCH_CLINIC_SUCCESS:
    case CREATE_CLINIC_SUCCESS:
        console.log('got clinic details:');
        console.log(result);
        return {...state, details: result}
    case FETCH_CLINIC_FAILURE:
    case CREATE_CLINIC_FAILURE:
    case LOGIN_FAILURE:
        return {...state, details: {}}
    default:
        return state;
    }
};

export default clinicReducer;
