const local = {
    apibase:'https://cmo.cmo.cmo:8443/', 
    baseUrl:'https://cmo.cmo.cmo:8443/',
    videoUrl: 'https://cmo.cmo.cmo:8443/WebRTC/chat/'
}

const prod = {
    apibase:'https://hush.secure.video:449/', 
    baseUrl:'https://hush.secure.video:449/',
    videoUrl: 'https://hush.secure.video:449/WebRTC/chat/'
}
const Config = (process.env.NODE_ENV === 'production') ? prod : local  
export default Config;