
import React from 'react'; 
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { TabView,TabPanel } from 'primereact/tabview';
import { Accordion, AccordionTab } from 'primereact/accordion';
import {DataView} from 'primereact/dataview';
import { withRouter } from 'react-router';

import * as Helper from '../../components/Helper';
import history from '../../../store/history'; 
import { getContact, fetchContactHistory } from '../../../actions/contacts'; 
import UserDetailsCTX from './UserDetailsCTX';
import Settings from '../Settings/SettingsList'; 
import Plans from '../Billing/Plans';
import Cards from '../Billing/CardList';
import UserLogs from './UserLogs';

const UserDetailsWithId = withRouter(UserDetailsCTX) ;

class UserViewWithId extends React.Component {
    constructor(props) {
        super(props);  
        console.log('UserView');
        this.tabUlrs = ['/profile','/profile/plans','/profile/cards','/profile/history','/profile/widget'];
        this.state = { 
            activeIndex: props.activeIndex || 0,
            user_id: props.user_id || 1,
            user: props.user.details,
            info: props.user.info || {isMobile: false},
            history: history.hashHistory
        }; 
        const location= props.location;
        if (location && location.pathname) {
            // if (location.pathname.indexOf('/subscription')>-1) this.state.activeIndex=1;
            if (location.pathname.indexOf('/plans')>-1) this.state.activeIndex=1;
            if (location.pathname.indexOf('/cards')>-1) this.state.activeIndex=2;
            if (location.pathname.indexOf('/history')>-1) this.state.activeIndex=3;
            if (location.pathname.indexOf('/widget')>-1) this.state.activeIndex=4;
        }
        this.onTabChange = this.onTabChange.bind(this);
    }  
    onTabChange(e) {
        this.state.history.push(this.tabUlrs[e.index]);
        this.setState({activeIndex: e.index});
    }
    render() { 
        return ( 
        <div> 
        {this.state && !this.state.info.isMobile &&
            <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.onTabChange(e)}>
                <TabPanel header="User Profile">
                    <UserDetailsWithId props={this.state} />
                </TabPanel>
                <TabPanel header="Plans">
                    <Plans history={this.state.history} />
                </TabPanel>
                <TabPanel header="Cards">
                    <Cards history={this.state.history} />
                </TabPanel>
                <TabPanel header="History"> 
                    <UserLogs {...this.state} />
                </TabPanel> 
                <TabPanel header="Integration"> 
                    <Settings />
                </TabPanel> 
            </TabView> 
        }
        {this.state && this.state.info.isMobile &&
            <Accordion activeIndex={this.state.activeIndex}  onTabChange={(e) => this.onTabChange(e)}>
                <AccordionTab header="User Profile">
                    <UserDetailsWithId props={this.state} />
                </AccordionTab> 
                <AccordionTab header="Plans">
                    <Plans history={this.state.history} />
                </AccordionTab>
                <AccordionTab header="Cards">
                    <Cards history={this.state.history} />
                </AccordionTab>
                <AccordionTab header="History"> 
                    <UserLogs {...this.state} />
                </AccordionTab> 
                <AccordionTab header="Integration"> 
                    <Settings />
                </AccordionTab>
            </Accordion>
        }
        </div>  
    );
}
}
const mapStateToProps = (state, props) => {
    console.log('UserView mapState'); 
    console.log(state);
    let newState={...state}; 
    console.log(newState);
    return newState;
  }
const mapDispatchToProps = (dispatch, props, state) => {
  console.log('bindActionCreators getContactInfo')
  return bindActionCreators({ getContact, fetchContactHistory }, dispatch);
}

const UserViewCTX = connect(mapStateToProps, mapDispatchToProps)(UserViewWithId)
const UserView = withRouter(UserViewCTX);
export default UserView;
