
import React from 'react'; 
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { TabView,TabPanel } from 'primereact/tabview';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataView } from 'primereact/dataview';
import { withRouter } from 'react-router'
import history from '../../../store/history'; 

import * as Helper from '../../components/Helper'; 
import { fetchContactHistory } from '../../../actions/contacts'; 
import ContactDetailsCTX from './ContactDetailsCTX';
import './contacts.css';

const ContactDetailsWithId = withRouter(ContactDetailsCTX) ;

class ContactViewWithId extends React.Component {
    constructor(props) {
        super(props);  
        console.log('ContactView');
        this.defaultPageSize = 10;
        this.tabUlrs = ['','history'];
        this.state = {
            activeIndex: 0,
            info: props.user.info || {isMobile: false},
            contact_list: props.contact_list || [],
            contact_id: props.contact_id || 1,
            contact: props.contact_list && props.contact_list.current ? props.contact_list.current : null,
            logs: {},
            history: history.hashHistory
        }; 

        if (props.match.params.id) {
            this.contact_id = props.match.params.id;
            this.tabUlrs = [`/contacts/${this.contact_id}`,`/contacts/${this.contact_id}/history`]; 
            this.contact_id = this.contact_id.toUpperCase();
            if (this.state.contact_list) {
                this.contact = Helper.getItemFromArray(this.state.contact_list,this.contact_id,'Id');
            } 
            if (!this.state.contact) {
                this.state = {...this.state, contact_id:this.contact_id, contact:this.contact || {}}
            } 
        } 
        if (props.contact_list && props.contact_list.current && props.contact_list.current.logs) { 
            this.state.logs.records = this.state.contact.logs.records || [];
            this.state.logs.totalRecords = this.state.contact.logs.totalRecords || 0; 
            this.state.logs.page = this.state.contact.logs.pageNumber -1 || 0;
            this.state.logs.rows = this.state.contact.logs.pageSize || this.defaultPageSize;
            this.state.logs.first =(this.state.logs.page ) * this.defaultPageSize;
             
          }
        const location= props.location;
        if (location && location.pathname) { 
            if (location.pathname.indexOf('/history')>-1) this.state.activeIndex=1; 
        }
        this.onPage = this.onPage.bind(this); 
        this.rowTemplate = this.rowTemplate.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.loadLogData = this.loadLogData.bind(this);
    }
    
    componentDidMount() {
        if (this.state.contact && !this.state.contact.logs ) { 
            this.props.fetchContactHistory(this.contact_id, 0,10);
        }
        this.logFetcher = setInterval(this.loadLogData, 5000);
        console.log('enabling loadLogData');
    }

    async loadLogData() {
        if (this.state.activeIndex === 1) {
            console.log('calling loadLogData');
            try {
                this.props.fetchContactHistory(this.contact_id, this.state.startIndex, this.defaultPageSize);
            } catch (e) {
                console.log(e);
            }
        }
    }
    componentWillUnmount(){
        console.log('disabling loadLogData');
        clearInterval(this.logFetcher);
    }
    onTabChange(e) {
        this.state.history.push(this.tabUlrs[e.index]);
        this.setState({activeIndex: e.index});
    }
    rowTemplate(row) {
        if(!row) { 
            return null;
        } 
        return (
              <div className="row-details p-grid"> 
                {row.Connected && 
                <div className="p-col-1 min3em row-cell">
                    <i className="pi pi-thumbs-up" style={{'fontSize': '1.2em', color:'limegreen'}}></i> 
                </div>
                }
                {row.Connected === false && 
                <div className="p-col-1 min3em row-cell">
                    <i className="pi pi-thumbs-down" style={{'fontSize': '1.2em', color:'orangered'}}></i> 
                </div>
                }

                {row.Email && row.Email.length > 0 && 
                <div className="p-col-1 row-cell">
                    <i title={row.Email} className="pi pi-envelope" style={{'fontSize': '1em'}}></i> 
                </div>
                }
                {row.Email && row.Phone.length > 0 && 
                <div className="p-col-1 row-cell">
                    <i title={row.Phone} className="pi pi-mobile" style={{'fontSize': '1em'}}></i> 
                </div>
                }


                {row.Connected && 
                <div className="p-col-2 hidden-xs-down row-cell"> 
                    Connected
                </div>
                }
                {row.Connected === false && 
                <div className="p-col-2 hidden-xs-down row-cell"> 
                    No answer
                </div>
                }
                <div className="p-col-2 row-cell">{row.EntryDate}</div>
                <div className="p-col-2 row-cell">{row.StartedAt}</div> 
                <div className="p-col-2 row-cell">{row.Duration} min</div>
                     
    
    {/*                   
                    <div className="ui-g-2 ui-sm-6">Email: </div>
                    <div className="ui-g-10 ui-sm-6">{contact.Email}</div> */}
              </div>
        );
    }
    onPage(event) {  
      const startIndex = 1 + event.first/this.defaultPageSize; 
      this.props.fetchContactHistory(this.contact_id, startIndex, this.defaultPageSize);
      this.setState({
          loading: true,
          startIndex
      });
    }
    render() { 
        if (this.state.contact && this.state.contact.logs) {
        console.log(this.state.contact.logs);
        }
        return ( 
        <div> 
        {this.state && !this.state.info.isMobile &&
        <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.onTabChange(e)}>
            <TabPanel header="Profile">
                <ContactDetailsWithId props={this.state} />
            </TabPanel>
            
            {/* <DataView value={this.state.contact.logs.records} layout="list" itemTemplate={this.rowTemplate} /> */}
            <TabPanel header="History">
                {this.state.contact && this.state.logs &&
                <DataView value={this.state.logs.records} layout="list" itemTemplate={this.rowTemplate}
                paginator={true} rows={this.state.logs.rows} 
                first={this.state.logs.first}   
                lazy  totalRecords={this.state.logs.totalRecords} paginatorPosition={'top'}
                onPage={this.onPage} loading={this.state.loading} >
                </DataView>
                }
                {this.state.contact && !this.state.contact.logs &&
                <div>You did not call this person yet.</div>
                }
            </TabPanel>
            {/* <TabPanel header="Notes">
                Here goes list of notes/comments 
            </TabPanel> */}
        </TabView> }
        {this.state && this.state.info.isMobile &&
         <Accordion activeIndex={this.state.activeIndex} onTabChange={(e) => this.onTabChange(e)}>
            <AccordionTab header="Profile">
                <ContactDetailsWithId props={this.state} />
            </AccordionTab> 
            <AccordionTab header="History">
                {this.state.contact && this.state.contact.logs &&
                <DataView value={this.state.contact.logs.records} layout="list" itemTemplate={this.rowTemplate} ></DataView>
                }
                {this.state.contact && !this.state.contact.logs &&
                <div>You did not call this person yet.</div>
                }
            </AccordionTab> 
        </Accordion>
        }
        </div>  
    );
}
}
const mapStateToProps = (state, props) => {
    console.log('ContactView mapState'); 
    console.log(state);
    let newState={
      error: state.error && state.error.message,
      token: state.user && state.user.details && state.user.details.JwtToken,
      contact_list: state.contact
    }; 
    console.log(newState);
    return newState;
  }
const mapDispatchToProps = (dispatch, props, state) => { 
  return bindActionCreators({ fetchContactHistory }, dispatch);
}

const ContactView = connect(mapStateToProps, mapDispatchToProps)(ContactViewWithId)
export default ContactView;
