import React from 'react'
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router'
import ListInline from './ListContactsInline'; 
import ContactView from './ContactView';

const ContactDetailsWithId = withRouter(ContactView) ;

export default class ListContacts extends React.Component {
  constructor (props) {
    super(props);
    console.log('ListContacts constructor');
    // this.props.fetchContacts(1,10,'', ''); 
  }

  render () {
    // it is in use
    return (  
      <>
      <Switch>
      <Route exact path='/contacts' component={() => <ListInline {...this.props}/>} />
      <Route path='/contacts/:id' component={() => <ContactDetailsWithId {...this.props}/>}/>
      <Route path='/contacts/add' component={() => <ContactDetailsWithId {...this.props}/>}/>
    </Switch> 
    </>
    )
  }
}
