import React from 'react';
import { InputText } from 'primereact/components/inputtext/InputText';
import {RadioButton} from 'primereact/components/radiobutton/RadioButton';

export default class ClaimDetails extends React.Component {
constructor (props) {
	super(props); 
	this.state = {
		 user: props.user && props.user.details || {}
 	};
	 
	this.handleFieldChange = this.handleFieldChange.bind(this); 
	console.log('future home of settings component');
	}
componentDidMount(){

}
handleFieldChange(e) {
	 let field_id=e.target.id;
	 let newSate={
		  ...this.state, settings:{ ...this.state.settings, [field_id]: e.target.value} }; 
	 this.setState(newSate);}
render () {
	console.log(this.state.currentState);
	const width100 = {width:'100%', resize: 'none'}; 
	var embed = '<' + 'script src="https://videochat.claimmanager.ca:8443/public/v2/loader.js" type="text/javascript"></' + 'script>';
	embed += `div id="hushvideo" data-apikey="${this.state.user.Id}" data-hostname="${this.state.user.FullName}"></div>`;
	return (
    <div className="container text-center" id="widget_page">
        <div className="layoutrow row">
            <div className="col-2">
                <i className="text-white fas fa-file-code fa-3x"></i>
            </div>
            <div className="col-8" >
                <p className="lead text-white">Please copy code below and paste it at your page.<br/>
                You can set data attributes directly in the code or let user enter information via user interface.
                </p>
            </div>
        </div> 
        <div className="layoutrow">
            <div className="col-md-12">
                <textarea id="widget" rows="4" style={width100} value = {embed} readOnly={true} >
                   
                </textarea>
            </div>

            <div className="panel panel-info">
                <div className="panel-heading">Data attributes</div>
                <div className="panel-body">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th width="20%">Attribute name</th>
                                <th>Meaning</th>
                                <th width="20%">Mandatory</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>data-apikey</td>
                                <td>API key provided by HushVideo during registration</td>
                                <td>yes</td>
                            </tr>
                            <tr>
                                <td>data-hostname</td>
                                <td>Your name that will be sent in the invitation so that guest knows who sent the invite</td>
                                <td>no</td>
                            </tr>
                            <tr>
                                <td>data-hostphone</td>
                                <td>Your phone number to be sent as a fallback way to communicate</td>
                                <td>no</td>
                            </tr>
                            <tr>
                                <td>data-guestname</td>
                                <td>The name of the invitee (inclided as a salutation in the invitation email and/or text)</td>
                                <td>no</td>
                            </tr>
                            <tr>
                                <td>data-guestemail</td>
                                <td>Email address where the invitation is sent</td>
                                <td>no</td>
                            </tr>
                            <tr>
                                <td>data-guestphone</td>
                                <td>Phone number where the invitation will be sent as a text message</td>
                                <td>no</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
	)
	}
}

