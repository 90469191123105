import React from 'react';
import './svideo.css';
export default class Chat extends React.Component {
constructor (props) {
	super(props);
	this.state = {};
}

render () { 
	const noshow = {display:'none'};
	const noshowAbs = {display:'none', position:'absolute'};
	const dialogStyle = { maxWidth:'90vw !important', margin:'auto'};
	const callBtnStyle = { height: '5em', top: '5px', width: '7em'}
	return (
		<div>
			<div id="entry">
				<h3>Welcome to HushVideo</h3>
				<div>
					<div id="invite"></div>
				</div>
				<div id="on-entry">
					<div id="entry-controls" role="toolbar" aria-label="Session controls">
						<div className="btn-group mr-2" role="group" aria-label="Mute">
							<button className="entryBtn btn btn-primary" onClick="{toggleOnEntry(0)}" title="Mute">
								<i id="entry-mic-icon" className="fas fa-microphone fa-4x"></i>
							</button>
						</div>
						<div className="btn-group mr-2" role="group" aria-label="Pause Video">
							<button className="entryBtn btn btn-primary" onClick="{toggleOnEntry(1)}" title="Pause Video">
								<i id="entry-video-icon" className="fas fa-video fa-4x"></i>
							</button>
						</div>
						<div id="start-landscape" className="btn-group mr-2 noshow" role="group" aria-label="Start Call">
							<button className="btn btn-danger prompt" style={callBtnStyle}
									onClick="{enterTheRoom()}" title="Start Call">Start</button>
						</div>
					</div>
					<div></div>
					<div className="entryHints"><ul></ul></div>
				</div>
				<div id="start-portrait">
					<div>
						<button className="btn btn-danger prompt" id="btn-enter" onClick="{enterTheRoom()}">Start</button>
						<button className="btn btn-danger prompt noshow" id="btn-exit" onClick="{endIntented()}">Exit</button>
					</div>
				</div>
			</div>
			<div id="blanket" className="noshow">
				<div id="fullscreen" style={noshow}></div>
				<div id="no-landscape" style={noshowAbs}>
					<div>Text chat works best in portrait mode.<br />Please rotate your iPhone.</div></div>
				<div><textarea id="shadow" rows="1" style={noshowAbs}></textarea></div>
				<div id="pool" style={noshowAbs}>
					<div id="chat-area" style={noshow}>
						<div id="chat-zone">
							<div className="chat-messages chat-scroll chat-fadein"></div>
						</div>
						<form className="compose">
							<textarea id="text-input" rows="4"></textarea>
							<button id="btn-send" className="btn btn-primary" onClick="{ sendChatMessage(); }" type="button" title="Send">
								<i id="msg-icon" className="fas fa-paper-plane fa-xs"></i>
							</button>
							<button id="btn-done" className="btn btn-primary noshow" onClick="{ doneWithMessage(); }"
									type="button" title="Ok" value="Ok">
								Ok
							</button>
						</form>
					</div>
				</div>
				<div id="area" className="container">
					<div className="row formcopy">
						<div id="left-rail" className="col-lg-12">
							<div id="pip" className="pip-noborder" style={noshow}>
								<div id="local-wrap">
									<span className="viwrap">
										<span id="local-video-text" className="fullw"></span>
										<video id="local-video" autoPlay muted playsInline></video>
										<span id="toggle-self" className="toggle-hide pointer" title="show/hide own video">&nbsp;</span>
									</span>
								</div>
							</div>
							<div id="rvideo">
								<span className="viwrap">
									<span id="remote-video-text" className="fullw"></span>
									<span id="rv-extra"></span>
									<video id="remote-video" autoPlay playsInline></video>
								</span>
							</div>
							<div id="lvideo">

							</div>
							<div> </div>
							<div className="btnHintRow" style={noshow}>
								<span className="btnHint"></span>
								<span onClick="toggleDevices()">
									<i className="fas fa-question fa-xs"></i>
								</span>
								<br />
							</div>
						</div>
						<div></div>
					</div>
				</div>

				<div className="btn-toolbar btn-footer" id="vc-controls" role="toolbar" aria-label="Session controls">
					<div className="btn-group mr-2 chatbtn" role="group" aria-label="Show Chat">
						<button className="videoBtn btn btn-primary" onClick="{toggleChat()}" title="Show Chat">
							<i id="chat-icon" className="fas fa-comment fa-xs"></i>
						</button>
					</div>
				 
					<div className="btn-group mr-2" role="group" aria-label="Mute">
						<button className="videoBtn btn btn-primary" onClick="{muteMicrophone()}" title="Mute">
							<i id="mic-icon" className="fas fa-microphone fa-xs"></i>
						</button>
					</div>
					<div className="btn-group mr-2" role="group" aria-label="Pause Video">
						<button className="videoBtn btn btn-primary" onClick="{pauseVideo()}" title="Pause Video">
							<i className="fas fa-video fa-xs" id="video-icon"></i>
						</button>
					</div>
					<div className="btn-group mr-2 screenshare noshow" role="group" aria-label="Share Screen">
						<button className="videoBtn btn btn-primary" id="share-button" onClick="{swap()}" title="Share Screen">
							<i id="swap-icon" className="fas fa-desktop fa-xs"></i>
						</button>
					</div>
					<div className="btn-group mr-2" role="group" aria-label="Reload">
						<button className="videoBtn btn btn-primary" title="Reload"
								onClick="{ safeReload();}">
							<i className="fas fa-sync fa-xs"></i>
						</button>
					</div>
					<div className="btn-group mr-2" role="group" aria-label="End Call">
						<button className="videoBtn btn btn-danger" title="End Call" data-toggle="modal" data-target="#confirmLeave">
							<i className="fas fa-phone-slash fa-xs"></i>
						</button>
					</div>
					
					{ this.state.debug &&
						<div className="btn-group mr-2" role="group" aria-label="Debug">
							<button className="videoBtn btn btn-primary" title="Debug"
									onClick="{showLogs()}">
								<i className="fas fa-align-justify fa-xs"></i>
							</button>
						</div>
					}
				</div>
				<div id="popupError"></div>
 
				<div className="modal fade noshow" id="confirmLeave" tabIndex="-1" role="dialog" aria-labelledby="confirmLeaveLabel" aria-hidden="true">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="confirmLeaveLabel">Do you want to end the meeting?</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<span id="guestText" style={noshow}>If you exit, you will need to request a new invitation link to reconnect with your host.</span>
								<span id="hostText" style={noshow}>If you exit, you will need to send a new invitation link to reconnect with your guest.</span>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" data-dismiss="modal" onClick="{ endIntented();}">Yes</button>
								<button type="button" className="btn btn-primary" data-dismiss="modal">No</button>
							</div>
						</div>
					</div>
				</div>

				<div className="modal fade noshow" id="errorMsg" tabIndex="-1" role="dialog" aria-labelledby="errorLabel" aria-hidden="true">
					<div className="modal-dialog" role="document" style={dialogStyle}>
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="errorLabel"></h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick="{ safeReload();}">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body" id="errorBody">

							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" data-dismiss="modal" onClick="{ safeReload();}">Close</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	 );}
}

