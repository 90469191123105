import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getContact, editContact, updateContactCommunications,
  deleteContact,inviteContact, fetchContactHistory } from '../../../actions/contacts'
import ContactDetails  from './ContactDetails'

const mapStateToProps = (state, props) => {
  console.log('ContactDetailsCTX mapState'); 
  console.log(state); 
  console.log(props); 
  return {...state }
}

const mapDispatchToProps = (dispatch, props, state) => {
  console.log('bindActionCreators editContact')
  return bindActionCreators({ getContact, editContact, updateContactCommunications,
    deleteContact, inviteContact, fetchContactHistory }, dispatch);
}

const ContactDetailsCTX = connect(mapStateToProps, mapDispatchToProps)(ContactDetails)
console.log('ContactDetailsCTX invoked');
export default ContactDetailsCTX
