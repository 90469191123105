import React from 'react';
import {
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
 
import Home from '../view/pages/home';
import '../assets/fonts/fonts.css';  

import Header from '../view/containers/Common/Header';
const JustAnotherPage = () => (
  <div>
    <h2>This is Just Another Page(inline)</h2>
    <p>Please remove this from your route, it is just to show case basic setup for router.</p>
  </div>
);

const HeaderWithRouter = withRouter(props => <Header {...props} />);

const exports = (
  <div className="container">
    <HeaderWithRouter />
    <hr />
    <h2>INSIDE ROUTES</h2>
    <div className="container__content">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/page" component={JustAnotherPage} /> 
        <Route path="/contacts" component={JustAnotherPage} /> 
        <Route path="*" component={Home} />
      </Switch>
    </div>
  </div>
);
export default exports;