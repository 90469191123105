
import React from 'react'; 
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'; 
import { withRouter } from 'react-router' 
 
import { InputText } from 'primereact/components/inputtext/InputText';
import { Button } from 'primereact/components/button/Button';
import { InputMask } from 'primereact/inputmask';
import {Panel} from 'primereact/panel';
import {Growl} from 'primereact/growl';

import Validator from '../../components/SmartForm';
import { addCard, clearCardError } from '../../../actions/billing';
import './plans.css';
 
class CardDetails extends React.Component {
    constructor(props) {
        super(props); 
        this.props = props;   
        this.state = {
            user: props.user.details,
            history: props.history,
            nc_ownername :'',
            nc_number :'',
            nc_expiry :'',
            nc_address :'',
            nc_city :'',
            nc_zip :'',
            nc_province :''
        };
        if (props.billing.card) {
            this.state.nc_ownername = props.billing.card.CardholderName;
            this.state.nc_number = props.billing.card.CardNumber; 
            this.state.nc_expiry = props.billing.card.ExpiryDate;
            this.state.nc_address = props.billing.card.AddressLine;
            this.state.nc_city = props.billing.card.City;
            this.state.nc_zip = props.billing.card.Zip;
            this.state.nc_province = props.billing.card.Province;
        } 
        console.log('this.state and props in CardDetails');
        console.log(this.state); 
        console.log(props); 
 
        this.handleSaveNewCard = this.handleSaveNewCard.bind(this); 
        this.handleFieldChange = this.handleFieldChange.bind(this);  
        this.gotoBack = this.gotoBack.bind(this); 
        this.clearError = this.clearError.bind(this);
        this.showCvvHint = this.showCvvHint.bind(this);
      }

componentDidMount(){
    if (this.props.billing.cardError) {
        this.growl.show({severity: 'warn', summary: 'Card Vaildation Failed', detail: this.props.billing.cardError});
    }
}
clearError(){
    this.growl.clear();
    if (this.props.billing.cardError) {
        this.props.clearCardError();
    }
}
gotoBack(){
    this.props.history.goBack();
}
showCvvHint(){
    window.open('https://www.cvvnumber.com/cvv.html','_blank',`toolbar=no,
    location=no,
    status=no,
    menubar=no,
    scrollbars=yes,
    resizable=yes,
    width=1024,
    height=350`);
}
handleFieldChange(e) {
    let field_id=e.target.id; 
    this.setState({[field_id]: e.target.value});
    e.preventDefault();
}

handleSaveNewCard(){
    const isValid = this.state.nc_number && Validator.cardIsValid(this.state.nc_number);
    let errors = [];
    this.growl.clear();
    if (!isValid) errors.push(<div id='e1'>Card Number is invalid.</div>);
    if (!this.state.nc_cvv) errors.push(<div id='e2'>Card Verification Value is missing.</div>);
    if (this.state.nc_email && !Validator.emailIsValid(this.state.nc_email)) {
        errors.push(<div id='ee'>Email Address is invalid.</div>); }
    if (!this.state.nc_expiry) errors.push(<div>Expiry Date is missing.</div>);
    if (!this.state.nc_ownername) errors.push(<div>Card holder Name is missing.</div>);
    if (!this.state.nc_address) errors.push(<div>Billing Address is missing.</div>);
    if (!this.state.nc_city) errors.push(<div>City is missing.</div>);
    if (!this.state.nc_province) errors.push(<div>Province is missing.</div>);
    if (!this.state.nc_zip) errors.push(<div>Postal Code is missing.</div>);
    if (errors.length > 0) {
        this.growl.show({severity: 'warn', summary: 'Invalid Card Information', detail: <div>{errors}</div>});
        return;
    }
    
    const cardInfo = {
        AccountId: this.state.user.AccountId,
        cardStatus: 0,
        IsPrimary: 1,
        cardholderName: this.state.nc_ownername,
        cardNumber: this.state.nc_number,
        cardNickname: null,
        expiryDate: this.state.nc_expiry,
        cardType: Validator.getCardType(this.state.nc_number),
        addressLine: this.state.nc_address,
        city: this.state.nc_city,
        zip: this.state.nc_zip,
        province: this.state.nc_province,
        email: this.state.nc_email,
        phone: this.state.nc_phone,
        DateAdded: new Date()
    }
    if (this.props.addCard) {
        this.props.addCard(this.state.user.AccountId, cardInfo );
    } 
}
render(){
    const autoWidth = { width:'auto'};
    const highlight ={marginRight:'-1em', marginLeft:'-1em', marginBottom:'-0.5em'}; 
    return (
        <Panel  header="New Card">
        <div className="p-fluid p-formgrid p-grid" style={highlight}>
            <div className="p-field p-col-12 p-md-4">
                <label htmlFor="nc_number">Card Number</label>
                <InputText id="nc_number" type="text"  value={this.state.nc_number}  onChange={(e)=>this.handleFieldChange(e)} maxLength={24} />
            </div>
            <div className="p-field p-col-12 p-md-1">
                <label htmlFor="nc_expiry">Expiry</label> 
                <InputMask id="nc_expiry" mask="99/99"  slotChar="MM/YY"  value={this.state.nc_expiry}  
                    onChange={(e)=>this.handleFieldChange(e)}></InputMask>
            </div>
            <div className="p-field p-col-12 p-md-1">
                <label htmlFor="nc_cvv">CVV</label>
                <i className="pi pi-question-circle" style={{fontSize:'small', marginLeft: '0.5em'}} onClick={this.showCvvHint}></i> 
                <InputMask id="nc_cvv" mask="999?9"  slotChar=""  value={this.state.nc_cvv}  
                    onChange={(e)=>this.handleFieldChange(e)}></InputMask>
            </div>
            <div className="p-field p-col-12 p-md-6">
                <label htmlFor="nc_ownername">Cardholder Name</label>
                <InputText id="nc_ownername" type="text" value={this.state.nc_ownername}  onChange={(e)=>this.handleFieldChange(e)} maxLength={100}  />
            </div>
            <div className="p-field p-col-12">
                <label htmlFor="nc_address">Billing Address</label>
                <InputText id="nc_address" type="text"  value={this.state.nc_address}  onChange={(e)=>this.handleFieldChange(e)} maxLength={150} />
            </div>
            <div className="p-field p-col-12 p-md-6">
                <label htmlFor="nc_city">City</label>
                <InputText id="nc_city" type="text" value={this.state.nc_city}   onChange={(e)=>this.handleFieldChange(e)} maxLength={50}  />
            </div>
            <div className="p-field p-col-12 p-md-3">
                <label htmlFor="nc_province">Province</label>
                <InputText id="nc_province" type="text" value={this.state.nc_province}   onChange={(e)=>this.handleFieldChange(e)} maxLength={2}  />
            </div>
            <div className="p-field p-col-12 p-md-3">
                <label htmlFor="nc_zip">Postal Code</label>
                <InputText id="nc_zip" type="text" value={this.state.nc_zip}   onChange={(e)=>this.handleFieldChange(e)} maxLength={7}  />
            </div>
            <div className="p-field p-col-12 p-md-3">
                <label htmlFor="nc_email">Email</label>
                <InputText id="nc_email" type="text" value={this.state.nc_email}  
                onChange={(e)=>this.handleFieldChange(e)} maxLength={50}  />
            </div>
            <div className="p-field p-col-12 p-md-3">
                <label htmlFor="nc_phone">Phone</label>
                <InputMask mask="(999) 999-9999? x99999" value={this.state.nc_phone}  
                placeholder="(999) 999-9999" id="nc_phone"  
                onChange={(e)=>this.handleFieldChange(e)}></InputMask>
                
            </div>
            <div className="p-col-12">
            <Button onClick={() => this.gotoBack()} style={autoWidth}
            className="p-button-raised p-button-secondary" label="Cancel"/> 
            <Button onClick={() => this.handleSaveNewCard()} style={autoWidth}
            className="p-button-raised p-button-primary mr1px" label="Validate"/> 
            </div>
        </div>
        <Growl ref={(el) => this.growl = el } onRemove={this.clearError} />
        </Panel>
    )
    }
}

const mapStateToProps = (state, props) => {
    console.log('CardDetails mapState'); 
    const details=props && props.billing ? props.billing.details : {}
    return {...state, details }
}
const mapDispatchToProps = (dispatch) => {
    console.log('bindActionCreators fetchSettings')
    return bindActionCreators({ addCard, clearCardError }, dispatch);
}

const CardDetailsWithId = withRouter(CardDetails) ;
const CardDetailsCTX = connect(mapStateToProps, mapDispatchToProps)( CardDetailsWithId);
export default CardDetailsCTX;