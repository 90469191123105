import {produce, setAutoFreeze} from "immer"
import cloneDeep from 'clone-deep';
import {
    CREATE_CONTACT_SUCCESS, EDIT_CONTACT, DELETE_CONTACT, GET_CONTACT_SUCCESS, FETCH_CONTACT_LOGS_SUCCESS,
    FETCH_CONTACTS_SUCCESS,CREATE_CONTACT_FAILURE, EDIT_CONTACT_SUCCESS, DELETE_CONTACT_SUCCESS, FETCH_CONTACT_LOGS_FAILURE
} from  '../../actions/actionTypes';
  
export default function contactsReducer (state = [], action) {
    console.log('contactsReducer');
    console.log(action);
    const { type, result } = action;
    switch (type) {
    case FETCH_CONTACTS_SUCCESS: {
        console.log('FETCH_CONTACTS_SUCCESS');
        return Object.assign( {}, state,  
            {list:result.records}, 
            {pageNumber: result.pageNumber},
            {pageSize: result.pageSize},
            {totalRecords: result.totalRecords},
            {query: result.query},
            {sortBy: result.sortBy},
            {sortOrder: result.sortOrder}, 
            {current: undefined}, 
            {gotContacts:true} );
    }
    case FETCH_CONTACT_LOGS_SUCCESS: {
        console.log('FETCH_CONTACT_LOGS_SUCCESS');
         
        return Object.assign( {}, state, {current:Object.assign( {}, state.current, {logs:result})});
    }
    case FETCH_CONTACT_LOGS_FAILURE: {
        console.log('FETCH_CONTACT_LOGS_FAILURE');
        return Object.assign( {}, state,  {...state.current, logs:undefined} );
    }
    case CREATE_CONTACT_SUCCESS: {
        console.log('CREATE_CONTACT_SUCCESS'); 
        return Object.assign( {}, state,   { current: result } ); // replaces Id in array!
    }
    case GET_CONTACT_SUCCESS: {
        console.log('GET_CONTACT_SUCCESS'); 
        return Object.assign( {}, state,  { current: result } )
    }
    case CREATE_CONTACT_FAILURE: {
        console.log('CREATE_CONTACT_FAILURE');
        return state;
    }
    case EDIT_CONTACT_SUCCESS: {
        var list = state.list.map(function(item) {
            if (item.Id === result.Id){
                return cloneDeep(result);
            } else {
                return cloneDeep(item);
            }
        }); 
        const nextState = produce(state, draftState =>
        {
            draftState.list = list; 
            draftState.current = result;
        });
        return nextState; 
    }
  
    case DELETE_CONTACT_SUCCESS: {
        const { type, id } = action
        return state.filter((patient, index) =>
            action.id !== index
        )
    }
  
    default:
        return state;
    }
}
  