import { connect } from 'react-redux'; 
import { bindActionCreators } from 'redux'
import { fetchUser, editUser, removeMethod } from '../../../actions/users';
import  UserDetails  from './UserDetails';

const mapStateToProps = (state) => {
  return { ...state } // error: state.error && state.error.message
}
const mapDispatchToProps = (dispatch, props, state) => {
  console.log('bindActionCreators editContact')
  return bindActionCreators({ fetchUser, editUser, removeMethod }, dispatch);
}
const UserDetailsCTX = connect(mapStateToProps, mapDispatchToProps)(UserDetails)

export default UserDetailsCTX