import { routerMiddleware } from 'react-router-redux';
import {
    applyMiddleware,
    compose,
    createStore,
} from 'redux';
 
import rootReducer from './reducers/rootReducers'; 
import thunkMiddleware from 'redux-thunk'

// Redux DevTools Extension for Chrome and Firefox
const reduxDevTool = () => {
    return typeof window === 'object' &&
  typeof window.devToolsExtension !== 'undefined' ? window.devToolsExtension() : f => f;
};

export default function configureStore(initialState, history) {
    //const sagaMiddleware = createSagaMiddleware();

    const middleware = applyMiddleware(thunkMiddleware,routerMiddleware(history));

    const composedStoreEnhancer = compose(middleware, reduxDevTool());

    const store = composedStoreEnhancer(createStore)(rootReducer, initialState);

    // sagaMiddleware.run(sagas);

    if (module.hot) {
        module.hot.accept('./reducers/rootReducers', () => {
            store.replaceReducer(require('./reducers/rootReducers'));
        });
    }

    return store;
}