import React from 'react'
import { InputText } from 'primereact/components/inputtext/InputText'; 
class InputWithClear extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholder: props.placeholder,
      value: '',
      dirty: false
    }; 
    if (props.value) {
      this.state.value = props.value;
      this.state.dirty = true;
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.hanldeBlur = this.hanldeBlur.bind(this);
    this.clearInput = this.clearInput.bind(this);
  }      
  clearInput(){
    this.setState({value:'', dirty:false});
    if (this.props.onChange) {
      this.props.onChange({target:{value:''}});
    } 
  }
  hanldeBlur(){
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }
  handleFieldChange(e) {  
    this.setState({ value: e.target.value, dirty: (e.target.value.length > 0)});
    if (this.props.onChange) {
      this.props.onChange(e);
    } 
  } 

  render() { 
    return (   
       <> 
          <InputText className="hv-fit-1btn hv-no-focus" 
            autoFocus  placeholder={this.state.placeholder} 
            value={this.state.value}  onBlur={this.hanldeBlur}
            onChange={(e) => this.handleFieldChange(e)}/> 
            {this.state.dirty &&

            <ul className="p-inputtext">
              <li className="p-chips-token p-highlight">
                <span className="p-chips-token-icon pi pi-fw pi-times" onClick={this.clearInput} style={{position:'unset'}}></span>  
              </li>
            </ul>
            }
            {this.state.dirty === false &&
            <ul className="p-inputtext">
              <li className="p-chips-token p-highlight">
                <span className="p-chips-token-icon pi pi-fw" style={{position:'unset'}}></span>  
              </li>
            </ul>
            } 
       </>
    )
  }
}
export default InputWithClear;
