import axios from 'axios';
import qs from 'qs'; 
import jwtDecode from 'jwt-decode';
import Config from '../config';
import * as actions from './actionTypes'
const setAuthHeader = (state) => {
    const currentState = state();
    const token = currentState && currentState.user && currentState.user.details ? currentState.user.details.JwtToken : '';
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
const getAccountId = (state) => {
    const currentState = state();
    return currentState && currentState.user && currentState.user.details ? currentState.user.details.AccountId : '';
  
}

const dispatchError = (type, error) => {
    return {
        type,
        results: error
    }
}

const formatList = (results, type) => {
    return {
        type,
        result: results.data
    }
};

export const createContact = (contact) => (dispatch) => {
    var url=`${Config.baseUrl}contacts`;
 
    axios.post(url, contact)
        .then((results)=> { 
            return dispatch(formatList(results, actions.EDIT_CONTACT_SUCCESS));
        })
        .catch(reason=>{
            console.log('error fetching contacts: ' + JSON.stringify(reason));
            return dispatchError(actions.EDIT_CONTACT_FAILURE,reason);
        })
}
export const editContact = (id, contact) => (dispatch) => {
    var url=`${Config.baseUrl}contacts/edit/${id}`;
 
    axios.put(url, contact)
        .then((results)=> { 
            return dispatch(formatList(results, actions.EDIT_CONTACT_SUCCESS));
        })
        .catch(reason=>{
            console.log('error updating the contact: ' + JSON.stringify(reason));
            return dispatchError(actions.EDIT_CONTACT_FAILURE,reason);
        })
}
export const updateContactCommunications = (id, contact) => (dispatch) => {
    var url=`${Config.baseUrl}contacts/edit/${id}?mode=1`;
    axios.put(url, contact)
        .then((results)=> { 
            return dispatch(formatList(results, actions.EDIT_CONTACT_SUCCESS));
        })
        .catch(reason=>{
            console.log('error updating contacts info: ' + JSON.stringify(reason));
            return dispatchError(actions.EDIT_CONTACT_FAILURE,reason);
        })
}
export const getContact = (id) => (dispatch) => {
    if (id === 'ADD') return;
    var url=`${Config.baseUrl}contacts/details/${id}`;
 
    axios.get(url)
        .then((results)=> { 
            return dispatch(formatList(results, actions.EDIT_CONTACT_SUCCESS));
        })
        .catch(reason=>{
            console.log('error fetching contacts: ' + JSON.stringify(reason));
            return dispatchError(actions.EDIT_CONTACT_FAILURE,reason);
        })
}

export const inviteContact = (owner_id, contact) => (dispatch, state) => {
    setAuthHeader(state);
    var url=`${Config.baseUrl}contacts/invite/${owner_id}`;
 
    axios.post(url, contact)
        .then((results)=> { 
            return dispatch(formatList(results, actions.GET_CONTACT_SUCCESS));
        })
        .catch(reason=>{
            console.log('error fetching contacts: ' + JSON.stringify(reason));
            return dispatchError(actions.GET_CONTACT_FAILURE,reason);
        })
}
export const deleteContact = (id) => {
    return {
        type: actions.DELETE_CONTACT_REQUEST,
        id,
    }
}
export const cleanCurrent = () => {
    const empty=[{
        Id: 'new',
        FirstName: '',
        LastName: '',
        Emails: [],
        Phones: []
    }]; 
    return {type: actions.GET_CONTACT_SUCCESS, result: empty};
}
export const setCurrent = (contact) => {  
    return {type: actions.GET_CONTACT_SUCCESS, result: contact};
}
 
export const fetchContactHistory = (id, page,pagesize) => (dispatch, state) => {
    if (id === 'ADD') return;
    setAuthHeader(state);
    const url=Config.baseUrl + 'contacts/logs/' + id +  '/' + (page ? page: '1') + '/' + (pagesize ? pagesize :'10');
    axios.get(url)
        .then((results)=> { 
            return dispatch(formatList(results, actions.FETCH_CONTACT_LOGS_SUCCESS));
        })
        .catch(reason=>{
            console.log('error fetching contacts: ' + JSON.stringify(reason));
            return dispatchError(actions.FETCH_CONTACT_LOGS_FAILURE,reason);
        })
}
export const fetchContacts = (page,pagesize,sortby, q) => (dispatch, state) => {
    setAuthHeader(state);
    const id = getAccountId(state);
    var url=Config.baseUrl + `contacts/list/${id}/` + (page ? page: '1') + '/' + (pagesize ? pagesize :'10');
    var qs = '';
    if (q) qs+=`q=${q}&`;
    if (sortby) qs += `sortby=${sortby}`;
    if (qs != '') url += `?${qs}`;
    axios.get(url)
        .then((results)=> { 
            return dispatch(formatList(results, actions.FETCH_CONTACTS_SUCCESS));
        })
        .catch(reason=>{
            console.log('error fetching contacts: ' + JSON.stringify(reason));
            return dispatchError(actions.FETCH_CONTACTS_FAILURE,reason);
        })
}; 