import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'; 
import { withRouter } from 'react-router-dom'; 
import { fetchCards, updatePrimaryCard, deleteCard } from '../../../actions/billing';
import CardListInline from './CardListInline'; 
import CardDetails from './CardDetails';

class Cards extends React.Component {
constructor (props) {
	super(props); 
	this.state = {
        viewMode: 0,
        user: (props.user && props.user.details) ? props.user.details : {},
        cardlist: (props.billing && props.billing.cardList) ? props.billing.cardList : null
 	};
	 
    this.handleFieldChange = this.handleFieldChange.bind(this); 
    this.handlePrimaryChange = this.handlePrimaryChange.bind(this);
    this.handleCardDelete = this.handleCardDelete.bind(this);
	console.log('future home of CardList component');
    const location= props.location;
    if (location && location.pathname) { 
        var idPosition = location.pathname.indexOf('/cards/');
        if (idPosition > -1) {
            idPosition += '/cards/'.length; 
            const mode=location.pathname.substring(idPosition); 
            if (mode === 'new') { this.state.viewMode=1; }
            }
        } 
	}
    componentDidMount(){
        if (!this.state.cardlist && this.props.fetchCards){
            this.props.fetchCards(this.state.user.AccountId);
        }
    }
    goBack(){
        this.props.history.goBack();
    }
    handleCardDelete(cardId){
        this.props.deleteCard(this.state.user.AccountId, cardId);
    }
    handleFieldChange(e) { 
        let field_id=e.target.id;
        let newSate={
            ...this.state, settings:{ ...this.state.settings, [field_id]: e.target.value} }; 
        this.setState(newSate);
    }
    handlePrimaryChange(e){
        let field_id=e.target.id;
        var cardlist = Object.assign([], this.state.cardlist);
        let card = cardlist.find(x => x.RowGuid === field_id);
        let selected = null;
        card.IsPrimary = e.target.value ? 1 : 0;
        if (e.target.value) {
            selected = field_id;
            cardlist.forEach(x=>{
                if ( x.RowGuid !== field_id) x.IsPrimary = 0;
            });
        }
        let newSate={
            ...this.state, cardlist, selectedCard: selected}; 
        this.setState(newSate);
        if (this.props.updatePrimaryCard) {
            this.props.updatePrimaryCard(this.state.user.AccountId, card);
        }
    }

render () {
	console.log(this.state);
	return (
    <div className="">
        {this.state.cardlist && this.state.viewMode === 0 &&
            <CardListInline cardList={this.state.cardlist} history={this.props.history}
            handlePrimaryChange = {this.handlePrimaryChange} 
            nextButtonLabel='Done' handleDelete ={this.handleCardDelete}
            goToNext = {(e)=>this.goBack()} 
            />
        } 
        {this.state.viewMode === 1 &&
            <CardDetails {...this.props} />
        } 
    </div>
	)
	}
}

const mapStateToProps = (state, props) => {
    console.log('CardList mapState'); 
    return {...state }
}
const mapDispatchToProps = (dispatch, props, state) => {
    console.log('bindActionCreators fetchCards')
    return bindActionCreators({ fetchCards, updatePrimaryCard, deleteCard }, dispatch);
}
const CardListCTX = connect(mapStateToProps, mapDispatchToProps)( Cards); 
const CardList = withRouter(CardListCTX);

export default CardList;