import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cloneDeep from 'clone-deep';

import ListContacts from './ListContacts'
import { fetchContacts, cleanCurrent, setCurrent, inviteContact } from '../../../actions/contacts'

const mapStateToProps = (state, props) => {
  console.log('ListPatientsCTX mapState'); 
  console.log(state);
  const contact_list = cloneDeep( state.contact);
  delete contact_list.current;
  let newState={
    user: state.user,
    error: state.error && state.error.message,
    token: state.user && state.user.details && state.user.details.JwtToken,
    contact_list,
    gotContacts:  state.contact && state.contact.gotContacts || false
  }; 
  console.log(newState);
  return newState;
}

const mapDispatchToProps = (dispatch, props, state) => {
  console.log('bindActionCreators fetchContacts')
  return bindActionCreators({ fetchContacts, cleanCurrent, setCurrent, inviteContact }, dispatch);
}

const ListContactsCTX = connect(mapStateToProps, mapDispatchToProps)(ListContacts)
console.log('ListContactsCTX invoked');
export default ListContactsCTX
