import React from 'react';
import qs from 'qs';
import Cookies from 'universal-cookie';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {InputMask} from 'primereact/inputmask';
import {Password} from 'primereact/password';
import {InputSwitch} from 'primereact/inputswitch';
import {Captcha} from 'primereact/captcha';
import {Growl} from 'primereact/growl';

import TwoFactorService from '../../../services/twofactor';
import Validator from '../../components/SmartForm';
import * as Helper from '../../components/Helper';

export default class Login extends React.Component {
  constructor (props) {
    super(props);
    this.state = { 
      user: props.user,
      isMobile:  Helper.isMobileDevice(),
      username: '', password: '', message: '', mode:'login', code:'',
      eula:false, local: false, rememberMe: false,
      picklists: {
        province: [{value:"AB", label: "AB"}, {value:"BC", label:"BC"}, {value:"SK", label:"SK"},{value: "MB", label:"MB"}, 
          {value:"ON", label:"ON"}, {value:"NB", label:"NB"}, {value:"NS", label:"NS"}, {value:"PE", label:"PE"},
          {value: "NF", label:"NF"}, {value:"NT", label:"NT"}, {value:"YT", label:"YT"},{value: "NU", label:"NU"}],
        business_type:[{value:'1', label:'Health Care Clinic'},{value:'2',label:'Medical Clinic'},{value:'3',label:'Hybrid'},{value:'43',label:'Other'}],
        user_type:[{value:'0', label:'Service Provider'},{value:'1',label:'Administrative Stuff'}] 
      },
      // reg_email:'test@secure.video',
      // reg_phone:'7789001234',
      // reg_pwd:'Qwerty2020!',
      // reg_pwd2:'Qwerty2020!',
      // reg_fname:'Shaitan',
      // reg_lname:'BadBoy',
      reg_email:'',
      reg_phone:'',
      reg_pwd:'',
      reg_pwd2:'',
      reg_fname:'',
      reg_lname:'',
      eula:false,
      captcha: false
    } 

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCode = this.handleCode.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleUsername = this.handleUsername.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleCaptcha = this.handleCaptcha.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleActivate = this.handleActivate.bind(this);
    this.handleRemember = this.handleRemember.bind(this);
  }
  componentDidMount(){
    const qsparams = qs.parse( window.location.search, { ignoreQueryPrefix: true });
    if (qsparams && qsparams.code) {
      this.handleActivate(qsparams.code);
    }
    const cookies = new Cookies();
    const saved = cookies.get('username');
    if (saved) {
      this.setState({username:saved, rememberMe:true})
    }
  }
  componentWillReceiveProps(nextProps) {
    console.log('will receive props: old');
    console.log(this.props);
    console.log('will receive props: new');
    console.log(nextProps);
    if (nextProps.user && nextProps.user.details && nextProps.user.details.FullName) {
      this.setState({ message: 'Welcome ' + nextProps.user.details.FullName });   
    } 
    if (!this.state.wrongLogin && nextProps.user && nextProps.user.error && this.growl ) {
      this.setState({ wrongLogin: true });   
      this.growl.show({severity: 'warn', summary: nextProps.user.error});
    } 
  }

  handleRemember(e){  
    this.setState({ rememberMe: e.target.checked })
  }
  handleUsername (evt) {
    this.setState({ username: evt.target.value })
  }

  handlePassword (evt) {
    this.setState({ password: evt.target.value })
  }

  handleSubmit (evt) {
    evt.preventDefault()
    // if (process.env.NODE_ENV ==='production' && this.state.username !== 'demo' && this.state.password !=='August2020') {
    //   this.setState({ message: 'Login failed!' })
    //   return;
    // }
    if (this.state.username.length < 4) {
      this.growl.show({severity: 'warn', summary: 'Please enter valid username'});
      return;
    }
    if (this.state.password.length < 4) {
      this.growl.show({severity: 'warn', summary: 'Please enter valid password'});
      return;
    }
    const cookies = new Cookies();
    if (this.state.rememberMe) {        
      cookies.set('username', this.state.username, { path: '/' });
    }
    else {
      cookies.remove('username', { path: '/' });
    }
    this.setState({ wrongLogin: false });
    this.props.login(this.state.username, this.state.password);
    
    this.props.getDeviceInfo({isMobile: this.state.isMobile});
    // this.setState({ message: 'Login successful!' })
  }
  async handleRegister(evt){
    evt.preventDefault();
    let errors = [];
    const isLocal = (window.location.href.indexOf('localhost')>-1)
    this.growl.clear(); 
    if (!this.state.reg_email) errors.push(<div id='e2'>Email Address is missing.</div>);
    if (this.state.reg_email && !Validator.emailIsValid(this.state.reg_email)) {
        errors.push(<div id='ee'>Email Address is invalid.</div>); }
    if (!this.state.reg_phone) errors.push(<div>Phone Number is missing.</div>);
    if (this.state.reg_phone && !Validator.phoneIsValid(this.state.reg_phone)) {
        errors.push(<div id='ee'>Phone Number is invalid.</div>); }
    if (!this.state.reg_pwd) errors.push(<div>Password is missing.</div>);
    if (!this.state.reg_pwd2) errors.push(<div>Password Confirmation is missing.</div>);
    if (this.state.reg_pwd && this.state.reg_pwd2 && 
      (this.state.reg_pwd !== this.state.reg_pwd2 || this.state.reg_pwd.length < 8 || this.state.reg_pwd2.length < 8)) {
        errors.push(<div>Password Confirmation does not match Password.</div>);
    }
    if (!this.state.reg_fname) errors.push(<div>First Name is missing.</div>);
    if (!this.state.reg_lname) errors.push(<div>Last Name is missing.</div>);
    if (!this.state.eula) errors.push(<div>Please read and accept Terms of Use.</div>);
    if (!this.state.captcha && !isLocal) errors.push(<div>Please confirm that you are human.</div>);
    if (errors.length > 0) {
        this.growl.show({severity: 'warn', life: 6000,
        summary: 'Insufficient Information', detail: <div>{errors}</div>});
        return;
    }
    const user_info={
      Emails:[{EmailAddress: this.state.reg_email}],
      Phones:[{PhoneNumber: this.state.reg_phone}],
      FirstName: this.state.reg_fname,
      LastName: this.state.reg_lname
    }
    const reply = await TwoFactorService.start2FA(user_info);
    if (reply.Success) {
      this.setState({RegPhoneId: reply.RowGuid, mode:'confirm'});
    }
    else {
      this.growl.show({severity: 'warn', summary: 'Could not send text message', detail: reply.Message});
    }
  }
  async handleActivate(code){
    const reply = await TwoFactorService.activate({code});
    if (reply.Success) {
      this.setState({mode:'alldone'});
    }
    else {
      this.growl.show({severity: 'warn', summary: 'Activate code is invalid or expired', life: 6000 });
    }
  }
  async handleConfirm(evt){
    if (evt) {
      evt.preventDefault();
    }
    const code = this.state.code || ''; 
    if (code.length !== 4 || !Helper.isNumeric(code)) {
      this.growl.show({severity: 'warn', summary: 'Invalid Verification Code'});
    }
    else {
      const phone_info={ 
        Phones:[{Id: this.state.RegPhoneId, PhoneNumber: this.state.reg_phone, PhoneType: code}] 
      }
      const reply = await TwoFactorService.verify2FA(phone_info);
      if (reply.Success) {
        const user_info={
          Emails:[{EmailAddress: this.state.reg_email}],
          Phones:[{PhoneNumber: this.state.reg_phone}],
          Password: this.state.reg_pwd,
          Title: this.state.reg_title,
          FirstName: this.state.reg_fname,
          LastName: this.state.reg_lname
        }
        // this.growl.show({severity: 'success', summary: 'Successfully Registered'});
        // this.props.register(user_info); 
        const regReply = await TwoFactorService.register(user_info);
        if (regReply.JwtToken) {
          this.growl.show({severity: 'success', summary: 'Successfully Registered'});
          this.setState({ mode:'complete'});
        }
        else {
          this.growl.show({severity: 'warn', summary: 'Registration failed', detail: regReply.Message});
        }
      }
      else {
        this.growl.show({severity: 'warn', summary: 'Invalid Verification Code'});
      } 
    }
  }
  handleCode(){
    const value = this.state.code || '';
    if (value.length !== 4 || !Helper.isNumeric(value)) {
      this.growl.show({severity: 'warn', summary: 'Invalid Verification Code'});
    }
    else {
      this.handleConfirm();
    } 
  }
  handleClose(){
    try{ 
      window.close();}
    catch(e){
      window.location.href='/';
    }
  }
  handleFieldChange(e) {
    const field_id=e.target.id;
    const value = e.target.value; 
    this.setState({[field_id]: value});

    // commented out due to async handleConfirm - it reads from state but setState did not finish yet
    // if (field_id === 'code' && value.length === 6 && Helper.isNumeric(value)) {
    //   this.handleConfirm();
    // }
  }
  handleCaptcha(){
    this.setState({captcha: true});
  }

  render () {
    const lp_btn  = {maxWidth:'20em',minWidth:'8em',padding:'0.5em'};
    const centered = {textAlign: 'center'}; 
    const resetText = {fontWeight:'normal',fontSize:'0.9em'};
    const loginFrame = {
      width: this.state.isMobile? '100%' :'50%',
      margin: 'auto'
    };
    const regFrame = {
      width: this.state.isMobile ? '100%' : '80%',
      margin: 'auto',
    };
    const panel = {
      // border: '1px solid #c0c0c0', 
      padding:'2em'
    }
    return (
      <>
      {this.state.mode === 'login' &&
      <form onSubmit={this.handleSubmit} style={loginFrame}>
        <div style={{marginTop:'1em'}}>
          <div className="ui-g hv-shadow" style={centered}>
              <div className="">
                  <div className="hv-title">Welcome to HushVideo</div>
              </div>
              <div className="p-fluid p-formgrid p-grid" style={panel} >    
                      
                      <div className="p-col-12 p-md-12 p-xl-12">
                          <span className="content"></span>
                      </div>
                      <div className="p-field p-col-12 p-md-6">
                          <label htmlFor="username">Username</label> 
                      </div> 
                      <div className="p-field p-col-12 p-md-5"> 
                          <InputText value={this.state.username} id="username" autoFocus
                          onChange={this.handleUsername}  />
                      </div>
                      <div className="p-field p-col-12 p-md-1">
                          <label></label> 
                      </div>
                      <div className="p-field p-col-12 p-md-6">
                          <label htmlFor="password">Password</label> 
                      </div>
                      <div className="p-field p-col-12 p-md-5"> 
                          <Password value={this.state.password} feedback={false} id="password"
                          onChange={this.handlePassword} />
                      </div>
                      
                      <div className="p-field p-col-12 p-md-1">
                          <label></label> 
                      </div>
                      <div className="p-field p-col-12 p-md-6">
                          <label></label> 
                      </div>
                      <div className="p-field p-col-12 p-md-5">
                          <input type="checkbox" onChange={this.handleRemember} id="rememberMe"
                          checked={this.state.rememberMe}/>
                          <span className="remember-me">Remember Me</span>
                      </div>
                      
                      <div className="p-field p-col-12 p-md-1">
                          <label></label> 
                      </div>
                      <div className="p-field p-col-12 p-md-6">
                          <label></label> 
                      </div>
                      <div className="p-field p-col-12 p-md-5">
                        {/* <a value="Register" href="#" style={{marginLeft:'0.5em'}}  
                          onClick={() => this.setState({mode:'register'})} >Register</a>   */}
                          <Button type="submit"  className="ui-button ui-widget ui-state-default ui-corner-all"
                          label="Login" id="btnLogin" onClick={this.handleSubmit} />                      
                      </div>
                      
                      <div className="p-field p-col-12 p-md-1">
                          <label></label> 
                      </div>
                      <div className="p-field p-col-12 p-md-6">
                          <label></label> 
                      </div>
                      <div className="p-field p-col-12 p-md-5"  style={centered}>
                          <a value="Register" href="#" 
                          onClick={() => this.setState({mode:'register'})} >Register</a>                      
                      </div>
                      
                      <div className="p-field p-col-12 p-md-1">
                          <label></label> 
                      </div>
                      <div className="p-col-12 p-md-12 p-xl-12" style={{ color: 'green' }}>
                        {!this.props.error ? this.state.message : ''}
                      </div>
                      <div className="right-side p-col-12 p-md-12 p-xl-12 ui-lg-6">
                          {/* <img className="login-logo" src="../layout/images/asset-login.jpg"/> */}
                      </div>
                  </div>
             
          </div>
        </div>
      </form>
      }
      {this.state.mode === 'register' &&
      <form onSubmit={this.handleRegister} style={regFrame}>
        <div className="ui-g  hv-shadow" style={{marginTop:'1em'}} >
        <div className="hv-title">Welcome to HushVideo</div>
          
        <div className="p-fluid p-formgrid p-grid" style={panel} >
          
          <div className="p-field p-col-12 p-md-6">
              <label htmlFor="reg_email">Your email</label>
              <InputText id="reg_email" value={this.state.reg_email} autoFocus
              type="text" onChange={(e)=> this.handleFieldChange(e)} />
          </div>
          <div className="p-field p-col-12 p-md-6">
              <label htmlFor="reg_phone">Your phone</label>
              {/* <InputText id="reg_phone" value={this.state.reg_phone} 
              type="text" onChange={(e)=> this.handleFieldChange(e)} /> */}
              <InputMask mask="(999) 999-9999" value={this.state.reg_phone}  id="reg_phone"
                placeholder="(999) 999-9999"   onChange={(e)=> this.handleFieldChange(e)}>               
              </InputMask>
          </div>
          <div className="p-field p-col-12 p-md-6">
              <label htmlFor="reg_pwd">Password</label>
              <Password id="reg_pwd"  value={this.state.reg_pwd}
              type="text" onChange={(e)=> this.handleFieldChange(e)} />
          </div>
          <div className="p-field p-col-12 p-md-6">
              <label htmlFor="reg_pwd2">Confirm Password</label>
              <Password id="reg_pwd2"  value={this.state.reg_pwd2}
              type="text" onChange={(e)=> this.handleFieldChange(e)} feedback={false} />
          </div>
          <div className="p-field p-col-12" style={{borderBottom:'1px solid #c0c0c0'}}></div>
          <div className="p-field p-col-12 p-md-2">
              <label htmlFor="reg_title">Title</label>
              <InputText id="reg_title" value={this.state.reg_title}
               type="text" onChange={(e)=> this.handleFieldChange(e)} />
          </div>
          <div className="p-field p-col-12 p-md-4">
              <label htmlFor="reg_fname">First Name</label>
              <InputText id="reg_fname" value={this.state.reg_fname}
               type="text" onChange={(e)=> this.handleFieldChange(e)} />
          </div>
          <div className="p-field p-col-12 p-md-6">
              <label htmlFor="reg_lname">Last Name</label>
              <InputText id="reg_lname" value={this.state.reg_lname}
               type="text" onChange={(e)=> this.handleFieldChange(e)} />
          </div>
          {/* <div className="p-field p-col-12 p-md-6">                     
          <label htmlFor="business">Your Business</label>                         
          <Dropdown options={this.state.picklists.business_type} id="business"
                    value={this.state.business_type}   className="padded div6"
                    onChange={(e) => this.handleDropdownChange(e,'business_type')} autoWidth={false} />
          </div>
          <div className="p-field p-col-12 p-md-6">         
          <label htmlFor="usertype">Who are you?</label>    
                      
          <Dropdown options={this.state.picklists.user_type} id="usertype"
                    value={this.state.user_type}   className="padded div6"
                    onChange={(e) => this.handleDropdownChange(e,'user_type')} autoWidth={false} />
          </div> */}
           
          <div className="p-col-12">
                  <InputSwitch id="eula" checked={this.state.eula} 
                     onChange={(e)=> this.setState({eula:e.target.value})} />
                  &nbsp;&nbsp;I accept <a href="/terms" >Terms of Use</a>
                  <br/><br/>
                  In particular, You agree 
                  <br/>
                  <span className="content" style={resetText}> 
                  (a) not access the Services if You are engaged in, concerned with, or interested in, 
                  a business that provides products or services that are similar to or compete with the Services 
                  or if You accessing the Services on behalf of such Person; (b) not impersonate someone 
                  else or falsely represent Your identity or professional qualifications.
                  </span>
          </div>
          <div className="p-col-12 p-md-6">
          <Captcha siteKey="6Lf3GsUZAAAAAAc5bPrBcTbNEeYFKb1V498J8fuu" 
          id="captcha" onResponse={this.handleCaptcha}></Captcha>
          </div>
          
          <div className="p-col-12 p-md-6" style={centered}>
     
            <div>
            {/* <a value="Login" href="#" 
                onClick={() => this.setState({mode:'login'})} >Login</a>     */}
                <Button type="submit"  className="ui-button ui-widget ui-state-default ui-corner-all"
                style={lp_btn} label="Register" onClick={this.handleRegister} />                      
            </div>
            
            <div style={{textAlign: 'center', marginTop:'1em'}}>
                <a value="Login" href="#"  
                onClick={() => this.setState({mode:'login'})} >Login</a>                      
            </div>
            <div style={{ color: 'green' }}>
              {!this.props.error ? this.state.message : ''}
            </div>
          </div>
      </div>
      </div>
      </form>}
      
      {this.state.mode === 'confirm' &&
      <form onSubmit={this.handleConfirm} style={loginFrame}>
        <div style={{marginTop:'1em'}}>
          <div className="ui-g hv-shadow" style={centered}>
              <div className="">
                  <div className="hv-title">Welcome to HushVideo</div>
              </div>
              <div className="p-fluid p-formgrid p-grid" style={panel} >    
                      
                      <div className="p-col-12 p-md-12 p-xl-12">
                          <span className="content">Enter the code that you have received on your phone to complete registration.</span>
                      </div>
                      <div className="p-field p-col-12 p-md-12"> 
                          <InputText value={this.state.code} id="code" 
                          maxLength={4} style={{...lp_btn, ...centered}} autoFocus
                          type="text" onChange={(e)=> this.handleFieldChange(e)} />
                      </div> 
                      
                      <div className="p-field p-col-12 p-md-12"> 
                          <Button type="submit"  className="ui-button ui-widget ui-state-default ui-corner-all"
                          style={lp_btn} label="Verify" id="btnVerify" />                      
                      </div>  
                  </div>
          </div>
        </div>
      </form>
      }
      {this.state.mode === 'complete' &&
      <form onSubmit={this.handleClose} style={loginFrame}>
        <div style={{marginTop:'1em'}}>
          <div className="ui-g hv-shadow" style={centered}>
              <div className="">
                  <div className="hv-title">Welcome to HushVideo</div>
              </div>
              <div className="p-fluid p-formgrid p-grid" style={panel} >    
                      
                      <div className="p-col-12 p-md-12 p-xl-12">
                        <span className="content">
                          We sent you an activation link at your email {this.state.reg_email}.<br>
                          </br>
                          Please click on the link to login to your new account.
                          </span>
                      </div>
                       
                      <div className="p-col-12 p-md-12 p-xl-12">
                        <span className="content">
                          You can close this window now.</span>
                      </div>
                      <div className="p-field p-col-12 p-md-12"> 
                          <Button type="submit"  className="ui-button ui-widget ui-state-default ui-corner-all"
                          style={lp_btn} label="Close" id="btnDone" />                      
                      </div>  
                  </div>
          </div>
        </div>
      </form>
      }
      {this.state.mode === 'alldone' && 
      <form onSubmit={this.handleClose} style={loginFrame}>
        <div style={{marginTop:'1em'}}>
          <div className="ui-g hv-shadow" style={centered}>
              <div className="">
                  <div className="hv-title">Welcome to HushVideo</div>
              </div>
              <div className="p-fluid p-formgrid p-grid" style={panel} >    
                      
                      <div className="p-col-12 p-md-12 p-xl-12">
                        <span className="content">
                          Your account verification successfully completed. 
                          Please <a href="/">login to your new account</a>.
                          </span>
                      </div>  
                  </div>
          </div>
        </div>
      </form>
      }
      <Growl ref={(el) => this.growl = el} />
      </>
    )
  }
}
