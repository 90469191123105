import React from 'react';
import LoginCTX from '../Users/LoginCTX';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      store: props.store,
      user: props.user
    };
  }      
  componentDidMount() {
    
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      store: nextProps.store,
      user: nextProps.user
    });
  }
  render() {
    let LoggedIn = false;
    if (this.state.user && this.state.user.details) {
      LoggedIn = true;
      console.log(this.state.user.details);
    }
    console.log('Logged in: ' + LoggedIn.toString());  
    return (
      <div> 
        { !LoggedIn && <div><LoginCTX store={this.state.store} /></div> }
        { LoggedIn &&
          <div>
            {/* <b>Logged in as:</b>{this.state.user.details.LoginName || this.state.user.details.FullName} */}
            
          </div>
        } 
    </div>
    )
  }
}
export default Header;
