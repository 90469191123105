import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Settings from './Settings'
import { fetchSettings } from '../../../actions/users'
const mapStateToProps = (state, props) => {
	 console.log('SettingsList mapState'); 
	 return {...state }
}
const mapDispatchToProps = (dispatch, props, state) => {
	 console.log('bindActionCreators fetchSettings')
	 return bindActionCreators({ fetchSettings }, dispatch);
}
const SettingsList = connect(mapStateToProps, mapDispatchToProps)( Settings);
export default SettingsList;

