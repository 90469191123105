import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'; 
import { withRouter } from 'react-router-dom'; 
import { fetchPlans, fetchCards,deleteCard } from '../../../actions/billing';
import { fetchPlanHistory, switchPlan, setSwitchPlan } from '../../../actions/users';
import { InputSwitch} from 'primereact/inputswitch';
import { DataView } from 'primereact/components/dataview/DataView';
import { DataTable } from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Steps} from 'primereact/steps';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {Growl} from 'primereact/growl';
import LinkButton  from '../../components/Elements/LinkedButton';
import CardListInline from './CardListInline.jsx';
import 'primeflex/primeflex.css';
import './plans.css';

class Plans extends React.Component {
constructor (props) {
    super(props); 
    this.steps = [
        {label: 'Plan', command: () => { this.setStep(0); }},
        {label: 'Payment Method', command: () => { this.setStep(1); }},
        {label: 'Confirmation', command: () => { this.setStep(2); }}
    ];

	this.state = { 
        currentStep: -1,
        user: props.user.details,
        plans: props.billing && props.billing.list || null, 
        cardlist: props.billing.cardList,
        planChanges: props.user.planChanges,  
        wizard: props.user.wizard,
        selectedPlan: (props.user.wizard && props.user.wizard.selectedPlan ) ? props.user.wizard.selectedPlan : undefined
    }; 
    const location= props.location;
    if (location && location.pathname) { 
        var idPosition = location.pathname.indexOf('/plans/');
        if (idPosition > -1) {
            idPosition += '/plans/'.length; 
            const mode=location.pathname.substring(idPosition); 
            switch (mode) {
                case '': 
                    this.state.currentStep = -1; 
                    break;
                case 'change': 
                    this.state.currentStep = 0; 
                    break;
                case 'confirm':
                    this.state.currentStep = 2; 
                    break;
                default:  
                    this.state.currentStep = 1;
                    this.state.selectedPlan = mode;
                    break;

            } 
        }
    } 
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleNewCard = this.handleNewCard.bind(this); 
    this.handleFieldChange = this.handleFieldChange.bind(this); 
    this.handlePrimaryChange =  this.handlePrimaryChange.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
    this.showRowData = this.showRowData.bind(this);
    this.gotoLink = this.gotoLink.bind(this);
    this.goToPayment = this.goToPayment.bind(this);
    this.goToConfirm = this.goToConfirm.bind(this);
    this.setPlan = this.setPlan.bind(this);
    this.setStep = this.setStep.bind(this);
    this.handleDeleteCard = this.handleDeleteCard.bind(this);
	console.log('future home of billing component');
	}
componentDidMount(){
    if (!this.state.plans && this.props.fetchPlans){
        this.props.fetchPlans(null);
    }
    if (!this.state.planChanges && this.props.fetchPlanHistory){
        this.props.fetchPlanHistory(this.state.user.AccountId);
    }
    if (!this.state.cardlist && this.props.fetchCards){
        this.props.fetchCards(this.state.user.AccountId);
    }
}

handleDeleteCard(id) {
    if (this.props.deleteCard) { 
        this.props.deleteCard(this.state.user.AccountId, id);
    }
}
setPlan(e, plan){
    var planList = Object.assign([], this.state.plans);
    let selected = planList.find(x => x.PlanId === plan);
    let selectedPlan = null;
    selected.isSelected = e.target.value;
    if (e.target.value) {
        selectedPlan = plan;
        planList.forEach(x=>{
            if ( x.PlanId !== plan) x.isSelected = false;
        });
    }
    else {
        plan = undefined;
    }
    let newSate={
         ...this.state, plans: planList, selectedPlan}; 
    this.setState(newSate); 
}
goToPayment(){
    var selectedPlan = this.state.selectedPlan;
    if (!selectedPlan) {
        this.state.plans.forEach(x=>{
            if ( x.isSelected) selectedPlan=x.PlanId;
        });
        if (selectedPlan) {
            this.setState({selectedPlan});
        }
    }
    if (selectedPlan){
        this.setStep(1);
        this.props.history.push(`/profile/plans/${this.state.selectedPlan}`);
        this.props.setSwitchPlan({selectedPlan: this.state.selectedPlan});
    }
    else {
        this.growl.show({severity: 'warn', summary: 'Please select plan', detail: 'You need to select plan to be able to proceed'});
    }
}
goToConfirm(){
    const selectedCC = this.state.cardlist.find(x => x.IsPrimary === 1);
    if (selectedCC) {
        var selectedPlan = this.state.selectedPlan;
        if (typeof(selectedPlan) === 'string') selectedPlan=parseInt(selectedPlan,10);
        this.setStep(2);
        this.props.setSwitchPlan({selectedPlan, selectedCard: selectedCC});
        this.gotoLink('/profile/plans/confirm');
    }
    else{
        this.growl.show({severity: 'warn', summary: 'Please select a credit card', detail: 'You need to select credit card to be able to proceed'});
    }
}
handleFieldChange(e) {
	 let field_id=e.target.id;
	 let newSate={
		...this.state, [field_id]: e.target.value }; 
     this.setState(newSate);
}

handlePrimaryChange(e) {
    let field_id=e.target.id;
    var cardlist = Object.assign([], this.state.cardlist);
    let card = cardlist.find(x => x.RowGuid === field_id);
    let selected = null;
    card.IsPrimary = e.target.value ? 1 : 0;
    if (e.target.value) {
        selected = field_id;
        cardlist.forEach(x=>{
            if ( x.RowGuid !== field_id) x.IsPrimary = 0;
        });
    }
    let newSate={
         ...this.state, cardlist, selectedCard: selected}; 
    this.setState(newSate);
}

handleSwitch(){
    const paln_info={
        AccType: 'rgu',
        AccountId: "97424c8c-def2-42b3-8f54-7c422913b570", 
        BillingDate: 0,
        OwnerId: this.state.user.AccountId,
        PlanId: this.state.selectedPlan,
        CardId: this.state.selectedCard,
        StartedDate: new Date(), 
    }
    if (this.props.switchPlan) {
        this.props.switchPlan(this.state.user.AccountId, paln_info );
        this.gotoLink('/profile/plans');
    }
}
handleNewCard(){
    this.gotoLink('/profile/cards/new'); 
}

setStep(step) {
    // if (step>0) return;
    const index= step.index ? step.index : step; 
    // if (index === 0) {this.props.history.goBack();}
    this.setState({currentStep: index});  
}
gotoLink(target){
    this.props.history.push(target);
} 
showRowData(RowGuid){
    this.setState({activeRow:RowGuid});
}
actionTemplate(rowData) {
    return (
        <Button type="button" icon="pi pi-file-o" className="p-button-primary"
        //onClick={(e)=>this.gotoLink(`/profile/plans/${rowData.RowGuid}`)} 
        onClick={()=>this.showRowData(rowData.RowGuid)} 
        label="USAGE" ></Button>
    );
}

statusBodyTemplate(rowData) {
    if (rowData.AccountStatus === 'Active'){
        return <span style={{color: 'limegreen', fontWeight:'bold'}}>{rowData.AccountStatus}</span>;
    }
    return <span>{rowData.AccountStatus}</span>;
}

render () {
    console.log('palns:');
	console.log(this.state.plans);
    // marketing
    const header = [<i className="pi pi-user-minus" style={{'fontSize': '3em'}}></i>,
                    <i className="pi pi-user-plus" style={{'fontSize': '3em'}}></i>,
                    <i className="pi pi-users" style={{'fontSize': '3em'}}></i>]; 

    const today = new Date();
    var current={PlanName: 'Free', Brief: 'Free', selectedCard: {}};
    if (this.state.wizard) {
        current=this.state.plans.find(x=>x.PlanId === this.state.wizard.selectedPlan);
        current.selectedCard = this.state.wizard.selectedCard;
    }
	return (
    <div className="">
        {this.state.currentStep < 0 &&
        <div>
            <LinkButton className="p-button-raised p-button-secondary" 
                     to='/profile/plans/change' label="Change Plan" />  
            </div>
        }
        {this.state.planChanges && this.state.planChanges.length > 0 && this.state.currentStep < 0 &&
        <div> 
            <div style={{marginTop:'1em'}}>
                <DataTable value={this.state.planChanges}>
                    <Column field="StartedOn" header="Date" />
                    <Column field="PlanName" header="Plan" />
                    <Column field="AccountStatus" header="Status" body={this.statusBodyTemplate}  />
                    {/* <Column field="lastBill" header="Last Bill" />  */}
                    {/* <Column body={this.actionTemplate} style={{textAlign:'center', width: '8em'}}/> */}
                </DataTable>
            </div>
        </div>
        }
        
        {this.state.currentStep >= 0 &&
        <div className="p-col-12 p-md-12 p-xl-12">
            <Steps model={this.steps} 
            activeIndex={this.state.currentStep} onSelect={(e) => this.setStep(e)}  />  
        </div>
        }
        {this.state.currentStep === 0 && this.state.plans && 
        <div className="row-details p-grid"> 
            
                <div className="p-col-12 p-md-12 p-xl-12" style={{textAlign:'center'}}> 
                    <div style={{backgroundColor:'#7fbcec', width:'100%'}}
                    className="p-message p-component">Select Plan</div>
                </div>
        
        {this.state.plans.map((item, index) => {
            var footer =<span>           
            <InputSwitch  key={`cp_${item.PlanId}`} id={`${item.PlanId}`}
                        checked={this.state.plans[index].isSelected}
                        onChange={(e)=>this.setPlan(e,item.PlanId)} />
                        </span>;
            if (item.IsCurrent) {
                footer = <div 
                //className={classNames('customer-badge', 'status-active')}
                style={{ lineHeight: '2.3em'}}>Active</div>;
            } 
            return ( 
                <div className="p-col-4 p-md-4" key={`cd_${index}`}>
                <Card footer={footer} header={header[index]} key={`cd_${index}`}>
                    <div style={{minHeight:'5em'}}>
                    <strong>{item.PlanName}</strong><br/>{item.Brief}
                    </div>
                </Card>
                </div>  
             );
            })
        }
        
        <Button onClick={() => this.props.history.goBack()} 
            className="p-button-raised p-button-secondary" label="Cancel"/> 
            <Button onClick={() => this.goToPayment()} 
            className="p-button-raised p-button-primary mr1px" label="Next"/> 

            </div>
        }
        {this.state.currentStep === 1 && this.state.plans && this.state.selectedPlan && 
        <div>  
            <div className="p-grid p-fluid">  
            <div className="p-col-12 p-md-12 p-xl-12" style={{textAlign:'center'}}> 
                    <div style={{ backgroundColor:'#7fbcec', width:'100%'}} 
                    className="p-message p-component">Set Payment Method</div>
            </div>  
                <> 
                <div className="p-col-12 p-md-12 p-xl-12"> 
                {this.state.cardlist &&  
                    <CardListInline cardList={this.state.cardlist}  history={this.props.history}
                    handleNewCard = {this.handleNewCard} handlePrimaryChange = {this.handlePrimaryChange} 
                    handleDelete ={this.handleDeleteCard}
                    // goToNext = {this.goToConfirm} goToPrev = {(e) => this.setStep(1)}
                    />
                }
                </div>   
                </>
           
            </div>
            <Button onClick={() => this.props.history.goBack()} 
            className="p-button-raised p-button-secondary" label="Back"/> 
            <Button onClick={() => this.goToConfirm()} 
            className="p-button-raised p-button-primary mr1px" label="Next"/> 
        </div>} 
        {this.state.currentStep === 2 && this.state.wizard &&
        <div>
            <div className="p-col-12 p-md-12 p-xl-12" style={{textAlign:'center'}}> 
                    <div style={{backgroundColor:'#7fbcec', width:'100%'}}
                    className="p-message p-component">Confirm your selection</div>
                </div>
            <div className="p-col-12 p-md-12 p-xl-12">
                <label className="lp-label">You have selected <strong>{current.PlanName}</strong> plan.
                Your card {current.selectedCard.CardNumber} will be charged in accordance with Terms of use
                and plan provisions. 
                You new billing period starts on {today.toDateString()}</label>
            </div>   
           
            <Button onClick={() => this.props.history.goBack()} 
            className="p-button-raised p-button-secondary" label="Back"/> 
            <Button onClick={() => this.gotoLink('/profile/plans')} 
            className="p-button-raised p-button-secondary mr1px" label="Cancel"/> 
            <Button onClick={() => this.handleSwitch()} 
            className="p-button-raised p-button-primary mr1px" label="Confirm"/> 
        </div>
        }
        <Growl ref={(el) => this.growl = el} />
    </div>
	)
	}
}

const mapStateToProps = (state) => {
    console.log('SettingsList mapState'); 
    return {...state }
}
const mapDispatchToProps = (dispatch) => {
    console.log('bindActionCreators fetchPlans')
    return bindActionCreators({ fetchPlans, fetchCards, deleteCard,
        fetchPlanHistory, switchPlan, setSwitchPlan }, dispatch);
}
const PlansCTX = connect(mapStateToProps, mapDispatchToProps)( Plans);
const PlanList = withRouter(PlansCTX);
export default PlanList;