import fetch from 'isomorphic-fetch';
import { thunkCreator } from './utils';
import Config from '../config';
import {FETCH_PLANS_REQUEST,FETCH_PLANS_SUCCESS,FETCH_PLANS_FAILURE,
    EDIT_PLAN_REQUEST,EDIT_PLAN_SUCCESS,EDIT_PLAN_FAILURE,
    FETCH_PLAN_REQUEST,FETCH_PLAN_SUCCESS,FETCH_PLAN_FAILURE,
    FETCH_CARDS_REQUEST,FETCH_CARDS_SUCCESS,FETCH_CARDS_FAILURE,
    CREATE_CARD_REQUEST,CREATE_CARD_SUCCESS,CREATE_CARD_FAILURE,
    EDIT_CARD_REQUEST,EDIT_CARD_SUCCESS,EDIT_CARD_FAILURE, CLEAR_CARD_ERROR,
    DELETE_CARD_REQUEST,DELETE_CARD_SUCCESS,DELETE_CARD_FAILURE } from './actionTypes';

export const fetchCards = (id) => thunkCreator({
    types: [FETCH_CARDS_REQUEST,FETCH_CARDS_SUCCESS,FETCH_CARDS_FAILURE],
    promise: fetch(Config.baseUrl + `billing/cards/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //'Authorization': `Bearer ${token}` 
        },
    }) 
        .then(response => response.json())
        .catch((e)=> {return {id:'fail',name:'error'}})
}) 
export const addCard = (id, cardInfo) => thunkCreator({
    types: [CREATE_CARD_REQUEST,CREATE_CARD_SUCCESS,CREATE_CARD_FAILURE],
    promise: fetch(Config.baseUrl + `billing/cards/${id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //'Authorization': `Bearer ${token}` 
        },
        body: JSON.stringify(cardInfo)
    }) 
        .then(response => response.json())
        .catch((e)=> {return {id:'fail',name:'error'}})
}) 
export const updatePrimaryCard = (id, cardInfo) => thunkCreator({
    types: [EDIT_CARD_REQUEST,EDIT_CARD_SUCCESS,EDIT_CARD_FAILURE],
    promise: fetch(Config.baseUrl + `billing/cards/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //'Authorization': `Bearer ${token}` 
        },
        body: JSON.stringify(cardInfo)
    }) 
        .then(response => response.json())
        .catch((e)=> {return {id:'fail',name:'error'}})
}) 
export const deleteCard = (id, cardId) => thunkCreator({
    types: [DELETE_CARD_REQUEST,DELETE_CARD_SUCCESS,DELETE_CARD_FAILURE],
    promise: fetch(Config.baseUrl + `billing/cards/${id}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //'Authorization': `Bearer ${token}` 
        },
        body: JSON.stringify({RowGuid:cardId})
    }) 
        .then(response => response.json())
        .catch((e)=> {return {id:'fail',name:'error'}})
}) 
export const updateUserPlan = (id, userPlan) => thunkCreator({
	 types: [EDIT_PLAN_REQUEST,EDIT_PLAN_SUCCESS,EDIT_PLAN_FAILURE],
	 promise: fetch(Config.baseUrl + `claim/${id}`, {
	 method: 'PUT',
	 headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //'Authorization': `Bearer ${token}` 
        },
	 body: JSON.stringify(userPlan)
	 }) 
	 .then(response => response.json()) 
	 .catch(err=> { 
	 	return new Promise((resolve,reject)=>{ 
	 		reject('error' + err.message); 
	 		}); 
	 	}) 
}) 

export const fetchPlans = (token) => thunkCreator({
	 types: [FETCH_PLANS_REQUEST,FETCH_PLANS_SUCCESS,FETCH_PLANS_FAILURE],
	 promise: fetch(Config.baseUrl + 'plans', {
	 method: 'GET',
	 headers: {
		  'Accept': 'application/json',
		  'Content-Type': 'application/json',
		  //'Authorization': `Bearer ${token}` 
        },
	 }) 
	 .then(response => response.json())
}) 
export const fetchUserPlan = (token,id) => thunkCreator({
	 types: [FETCH_PLAN_REQUEST,FETCH_PLAN_SUCCESS,FETCH_PLAN_FAILURE],
	 promise: fetch(Config.baseUrl + `plans/details/${id}`, {
	 method: 'GET',
		  headers: {
		  'Accept': 'application/json',
		  'Content-Type': 'application/json',
		  //'Authorization': `Bearer ${token}` 
        },
	 }) 
	 .then(response => response.json())
	 .catch((e)=> {return {id:'fail',name:'error'}})
})  
export const clearCardError =() => (dispatch) =>{
    dispatch({type: CLEAR_CARD_ERROR, result: null});
}