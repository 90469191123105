import React from 'react';
import PropTypes from 'prop-types'; 
import { isNumeric } from '../Helper';
import tinycolor from 'tinycolor2';

const Initial = (props) => {
    const colors = ["#393E41", "#E94F37", "#1C89BF", "#A1D363",
        "#85FFC7", "#297373", "#FF8552", "#A40E4C"];
    const seed = (props.id === 'u') ? 8 : isNumeric(props.id) ? props.id : parseInt(`0x${props.id}`);
    
    const idx = seed % 8; // Math.round(8* Math.random());
    const circleStyle = {
        padding:10,
        margin:20,
        display:"inline-block",
        backgroundColor: colors[idx] ,
        borderRadius: "50%",
        width:100,
        height:100,
    };
    const letterStyle = {
        fontSize: "4em",
        //paddingLeft: "1.2rem",
        textAlign: 'center',
        color: '#000000'
    }
    const color = tinycolor(colors[idx]);
    if (color.isDark) {
        letterStyle.color='#ffffff';
    }
    if (props.small) {
        circleStyle.width='3em';
        circleStyle.height='3em';
        circleStyle.margin = 'unset';
        circleStyle.backgroundColor = colors[idx];
        letterStyle.fontSize='1em';
        letterStyle.fontWeight='bold';
    }

    return (
        <div style={Object.assign({}, circleStyle,props.style)}>
            <div style={letterStyle}>{props.letter}</div>
        </div>
    );
}

Initial.propTypes = { 
    id: PropTypes.string,
    letter: PropTypes.string
};

export default Initial;